import React, { createContext, useReducer } from "react";

//Initial State
const initialState = {
  quantidadeElementosPorPagina: 20,
  paginaAtual: 0,
  scrollInicio: 0,
  scrollFim: 4,
  rowsFiltrados: "",
};

function reducer(state, action) {
  switch (action.type) {
    //Atualiza filtros
    case "SET_DESPACHO":
      return setState(state, action);

    default:
      throw new Error(`Ação inválida!  -  ${action.type}`);
  }
}

const setState = (state, action) => {
  try {
    const { despacho } = action;

    return {
      ...state,
      ...despacho,
    };
  } catch (error) {
    console.log(error);
  }
};

//Create context
export const TabelaContext = createContext(initialState);

//Create provider
export const TabelaProvider = ({ children, rows, colunas, icones, onClickRow, search, destrava, despachantePai, paginationFilter, searchFilter }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  //Despachantes
  const despachante = (despacho) => {
    dispatch({ type: "SET_DESPACHO", despacho });
  };

  return (
    <TabelaContext.Provider
      value={{
        ...state,
        rows,
        colunas,
        icones,
        onClickRow,
        search,
        destrava,
        paginationFilter,
        searchFilter,
        despachantePai,
        despachante,
      }}
    >
      {children}
    </TabelaContext.Provider>
  );
};
