import React, { useRef, useEffect } from "react";
import jspreadsheet from "jspreadsheet-ce";
 
import "../../../../node_modules/jspreadsheet-ce/dist/jspreadsheet.css";
 
const ExcelGrid = ({ configuracao, setGrid }) => {
  const el = useRef(null);
 
  useEffect(() => {
    if (!el.current.jspreadsheet) {
      setGrid({ excelDados: jspreadsheet(el.current, configuracao) });
    }
  }, [configuracao]);
 
  const addRow = () => {
    el.current.jexcel.insertRow();
  };
 
  return (
    <div>
      <div ref={el} />
      <br />
      <input type="button" onClick={addRow} value="Adicionar linha" />
    </div>
  );
};

export default ExcelGrid;
