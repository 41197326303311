import React, { useContext, useEffect, useState } from "react";
import { IntegradorContext } from "../../util/context/context";
import { getOpcoes } from "../../../../helpers/requisicoesHttp/integradorRequisicoes";
import NovaOpcao from "./children/NovaOpcao";
import TabelaReact from "../../../../components/UI/TabelaReact/TabelaReact";
import Icone from "../../../../components/UI/Icone/Icone";
import Modal from "../../../../components/UI/Modal/Modal";
import EditarOpcao from "./children/EditarOpcao";

const Opcoes = () => {
   const {
      empresaSelecionada,
      contaContabilSelecionada,
      listaContas,
      search,
      destrava,
      despachante,
      opcaoSelecionada,
      modalOpcao,
   } = useContext(IntegradorContext);

   const [opcoes, setOpcoes] = useState([]);
   const [listaBanco, setListaBanco] = useState([]);

   useEffect(() => {
      if (empresaSelecionada && contaContabilSelecionada) {
         (async function fetch() {
            const opcoes = await getOpcoes(
               empresaSelecionada,
               contaContabilSelecionada
            );
            setListaBanco(opcoes);
            const listaOpcoes = opcoes.map((opcao) => {
               return [
                  opcao.codigo,
                  opcao.parte_historico,
                  opcao.operacao,
                  opcao.ct_partida,
                  opcao.historico,
                  opcao.sb_historico ? "Sim" : "Não",
               ];
            });
            setOpcoes(listaOpcoes);
         })();
      }
   }, [empresaSelecionada, contaContabilSelecionada]);

   // Modal Editar Opcao //
   const modalProps = {
      closeModal: () => {
         despachante({ modalOpcao: false });
      },
      title: "Editar Opção",
      isModalOpen: modalOpcao,
   };

   const handleModalEditar = (linhaIndex) => {
      despachante({
         opcaoSelecionada: opcoes[linhaIndex],
         opcaoSelecionadaBanco: listaBanco[linhaIndex],
         modalOpcao: true,
      });
   };
   //

   return (
      <div className="opcoes_container">
         {!empresaSelecionada && (
            <div className="alerta">
               <Icone icone="faExclamationTriangle" />
               Selecione a Empresa
            </div>
         )}
         {!contaContabilSelecionada && (
            <div className="alerta">
               <Icone icone="faExclamationTriangle" />
               Selecione a Conta Contábil
            </div>
         )}

         <NovaOpcao
            listaContas={listaContas}
            empresaSelecionada={empresaSelecionada}
            contaContabilSelecionada={contaContabilSelecionada}
         />

         {opcoes && (
            <TabelaReact
               colunas={[
                  "Código",
                  "Parte do Histórico",
                  "Tipo",
                  "Contra Partida",
                  "Histórico",
                  "Sobrescrever Histórico",
               ]}
               rowsFixo={opcoes}
               search={search}
               destrava={destrava}
               despachante={despachante}
               onClickRow={handleModalEditar}
            />
         )}

         <Modal {...modalProps}>
            <EditarOpcao />
         </Modal>
      </div>
   );
};

export default Opcoes;
