import React, { useState, useContext } from "react";
import { geraTabela } from "../../../util/controller";
import { AnaliseContext } from "../../util/context";
import Button from "../../../../../../../components/UI/Button/Button";
import Select from "../../../../../../../components/UI/Select/Select";
import { IntegradorContext } from "../../../../../util/context/context";
import useInput from "../../../../../../../helpers/customHooks/useInput";

const TemplateResultadoContabil = () => {
  //Context
  const { closeModal, historico, qualPromessa } = useContext(AnaliseContext);
  const {
    baixas,
    listaContas,
    despachante,
    despachaBaixaSelecionada,
    setSeparador,
    setLinhaSelecionada,
  } = useContext(IntegradorContext);

  //Variáveis
  const [contraPartida, setContraPartida] = useState("");

  const [codigoDoHistorico, codigoDoHistoricoInput] = useInput(
    "Código do Histórico",
    "",
    "codigoDoHistorico",
    ""
  );
  const [historicoValue, historicoInput] = useInput(
    "Histórico",
    "",
    "historico",
    historico
  );

  let template = (
    <>
      <div className="lancamentoContabilContainer">
        <Select
          options={listaContas}
          title="Conta Contábil"
          placeholder={"Selecione a conta!"}
          onChange={(valorSelecionado) => setContraPartida(valorSelecionado)}
          value={contraPartida}
        />
        {codigoDoHistoricoInput}
        {historicoInput}

        <Button
          classe="salvar_filtro"
          clicked={() => {
            const baixaSelecionada = {
              contraPartida,
              codigoDoHistorico,
              historicoValue,
              baixa_selecionada: true,
              tickColor: "green",
              tickIcon: "faCheck",
              operacao: 1,
            };

            despachaBaixaSelecionada(baixaSelecionada, qualPromessa, false);
            despachante({
              tabelaDados: geraTabela(
                baixas,
                setSeparador,
                setLinhaSelecionada
              ),
            });
            localStorage.setItem("Integrador", JSON.stringify(baixas));
            closeModal();
          }}
        >
          Salvar
        </Button>
      </div>
    </>
  );

  return template;
};

export default TemplateResultadoContabil;
