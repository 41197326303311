import React, { createContext, useReducer } from "react";
import { IntegradorContext } from "../../../../util/context/context";

//Initial State
const initialState = {};

//Reducer

function reducer(state, action) {
  switch (action.type) {
    //Atualiza filtros
    case "SET_ESTADO_INICIAL":
      return setInitialState(state, action);

    default:
      throw new Error(`Ação inválida!  -  ${action.type}`);
  }
}

const setInitialState = (state, action) => {
  const { despacho } = action;
  return {
    ...state,
    ...despacho,
  };
};

//Create context
export const AnaliseContext = createContext(initialState);

//Create provider
export const AnaliseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  //Despachantes
  const despachanteAnalise = (despacho) => {
    dispatch({ type: "SET_ESTADO_INICIAL", despacho });
  };

  return (
    <AnaliseContext.Provider
      value={{
        ...state,
        despachanteAnalise,
      }}
    >
      {children}
    </AnaliseContext.Provider>
  );
};
