import React, { useContext } from "react";
import { AnaliseContext } from "../../../util/context";
import { IntegradorContext } from "../../../../../../util/context/context";

const LIMITE_MAXIMO_BAIXAS_PARA_LISTAR = 500

import {
  confrontoPlanilhaNota,
  vencimentoFormatado,
  format,
  geraAtributos,
} from "../../../util/analiseController";

import Button from "../../../../../../../../components/UI/Button/Button";
import { geraTabela } from "../../../../util/controller";
import { ordenaPorProximidadeVencimentoEValor } from "../../../../util/constructors/Baixas/Baixas";

const TemplateResultados = () => {
  //Context
  const { data, baixasEncontradas, entrada, saida, closeModal, qualPromessa } =
    useContext(AnaliseContext);

  const {
    despachaBaixaSelecionada,
    despachante,
    baixas,
    setSeparador,
    setLinhaSelecionada,
    excelDados,
  } = useContext(IntegradorContext);


  //Function
  const onClickHandler = (index, padraoBaixaParcial) => {
    const baixaSelecionada = baixasValidas[index];
    despachaBaixaSelecionada(
      baixaSelecionada,
      qualPromessa,
      padraoBaixaParcial
    );
    despachante({
      tabelaDados: geraTabela(baixas, setSeparador, setLinhaSelecionada),
    });
    closeModal();
  };


  let baixasValidas = baixasEncontradas.length ? baixasEncontradas : entrada ? baixas.todasBaixasEmAbertoSaidaServico : baixas.todasBaixasEmAbertoEntrada
  baixasValidas = ordenaPorProximidadeVencimentoEValor(baixasValidas.filter((e) => e.vlconsiderado > 0), data, entrada || saida)
  baixasValidas = baixasValidas.slice(0, LIMITE_MAXIMO_BAIXAS_PARA_LISTAR)

  console.log("baixas listadas", baixasValidas);


  return (
    <div className="resultados_container">
      {baixasValidas &&
        Array.isArray(baixasValidas) &&
        baixasValidas.length > 0 &&
        baixasValidas.map((resultado, index) => {
          const value = entrada || saida;
          const safeValue = isNaN(value) ? value : Number.parseFloat(Number(value).toFixed(2))
          const safeValorconsiderado = isNaN(resultado.vlconsiderado) ? resultado.vlconsiderado : Number.parseFloat(Number(resultado.vlconsiderado).toFixed(2))
          let analiseValor = confrontoPlanilhaNota(
            safeValue,
            safeValorconsiderado
          );

          const valores = [
            resultado.codigo,
            resultado.nome,
            resultado.cnpj,
            resultado.documento,
            resultado.parcela,
            `${format(resultado.vltotal)}`,
            `${format(resultado.vlconsiderado)}`,
            vencimentoFormatado(resultado.vencimento),
          ];

          return (
            <div key={index} className={`analiseNotasContainer`}>
              <div
                className={`analiseNotasContainer__headBox ${analiseValor.classe}`}
              >
                Nota {index + 1}
              </div>

              <div
                className="analiseNotasContainer__info"
                onClick={() => onClickHandler(index, false)}
              >
                {geraAtributos(valores, atributosNota)}
              </div>
              {analiseValor.tipo < 2 && (
                <div className="analiseNotasContainer__aviso">
                  <p>{analiseValor.aviso}</p>
                  {analiseValor.tipo === 0 && (
                    <div className="analiseNotasContainer__aviso--btns">
                      <Button clicked={() => onClickHandler(index, false)}>
                        Continuar
                      </Button>
                    </div>
                  )}
                  {analiseValor.tipo === 1 && (
                    <div className="analiseNotasContainer__aviso--btns">
                      <Button clicked={() => onClickHandler(index, false)}>
                        Desconto
                      </Button>
                      <Button clicked={() => onClickHandler(index, true)}>
                        Baixa Parcial
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

const atributosNota = [
  `Código: `,
  `Nome: `,
  `CNPJ: `,
  `Documento: `,
  `Parcela: `,
  `Valor Total: `,
  `Valor em aberto: `,
  `Vencimento: `,
];

export default TemplateResultados;
