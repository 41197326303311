import React, { useContext, useState } from "react";
import Select from "../../../../../../../components/UI/Select/Select";
import { IntegradorContext } from "../../../../../util/context/context";
import { getNotasEmAberto } from "../../../../../../../helpers/requisicoesHttp/integradorRequisicoes";
import useLoading from "../../../../../../../helpers/customHooks/useLoading";
import { AnaliseContext } from "../../util/context";

const TemplateGeraFiltro = ({ render }) => {
  const {
    empresaSelecionada,
    listaFornecedores,
    listaServicos,
    listaClientes,
  } = useContext(IntegradorContext);
  const { despachanteAnalise, entrada } = useContext(AnaliseContext);

  const [loading, setLoading] = useLoading(false);
  const [qualRota, setQualRota] = useState("");
  //----------Select Opcao----------//
  const opcaoSelect = async (selectedOption) => {
    setQualRota(selectedOption);
  };

  const selectOpcaoProps = {
    title: "Classificação",
    value: qualRota,
    options: entrada
      ? [
          { value: "contabil", label: "Baixa Contábil" },
          { value: "saida", label: "Baixa de Saída" },
          { value: "servico", label: "Baixa de Serviço" },
        ]
      : [
          { value: "contabil", label: "Baixa Contábil" },
          { value: "entrada", label: "Baixa de Entrada" },
        ],
    placeholder: "Selecione a Baixa que deseja",
    isClearable: false,

    onChange: opcaoSelect,
  };

  //Funções
  const onChangeHandler = async (selectedOption) => {
    if (qualRota.value !== "contabil") {
      setLoading(true);

      let qualEmpresa = empresaSelecionada;

      let qualFornecedor = selectedOption.value;

      let notasEmAberto = await getNotasEmAberto(
        qualEmpresa,
        qualRota.value,
        qualFornecedor
      );

      despachanteAnalise({ baixasEncontradas: notasEmAberto });
      setLoading(false);
    }
  };
  const geraSelect = (qualRotaEscolhida) => {
    let title;
    let lista;
    let placeHolder;

    if (qualRotaEscolhida === "contabil" || !qualRotaEscolhida) {
      return null;
    }

    if (qualRotaEscolhida === "entrada") {
      title = "Fornecedores";

      lista = listaFornecedores.map((fornecedor) => {
        const { cgce_for, nome_for, codi_for } = fornecedor;
        return { value: codi_for, label: `${nome_for}: ${cgce_for}` };
      });
      placeHolder = "Selecione o fornecedor";
    }

    if (qualRotaEscolhida === "servico") {
      title = "Clientes";

      lista = listaServicos.map((cliente) => {
        const { cgce_cli, nome_cli, codi_cli } = cliente;
        return { value: codi_cli, label: `${nome_cli}: ${cgce_cli}` };
      });
      placeHolder = "Selecione o cliente";
    }

    if (qualRotaEscolhida === "saida") {
      title = "Clientes";

      lista = listaClientes.map((cliente) => {
        const { cgce_cli, nome_cli, codi_cli } = cliente;
        return { value: codi_cli, label: `${nome_cli}: ${cgce_cli}` };
      });

      placeHolder = "Selecione o cliente";
    }

    const selectProps = {
      title: title,
      options: lista,
      placeholder: placeHolder,
      isSearchable: true,
      onChange: (e) => onChangeHandler(e, qualRotaEscolhida),
    };

    return <Select {...selectProps} />;
  };

  return (
    <>
      <Select {...selectOpcaoProps} />
      {geraSelect(qualRota.value)}
      {loading} {render && render(qualRota.value)}
    </>
  );
};

export default TemplateGeraFiltro;
