import React from "react";
import ListagemPaginacao from "./ListagemPaginacao/ListagemPaginacao";

const Pagination = () => {
  return (
    <div className="tabelaReact__paginacaoContainer">
      <div className="tabelaReact__PaginacaoListagem">
        <ListagemPaginacao />
      </div>
    </div>
  );
};

export default Pagination;
