import React, { Component } from "react";
import ReactSelect from "react-select";
import { FixedSizeList as List } from "react-window";

const height = 30;

class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

/**
 * @param props {
 * @param title: titulo do Select
 * @param options: itens que o select vai carregar
 * @param onChange: função de Dispatch CASO você esteja fazendo um controle de estado por reducer (useReducer)
 * @param placeholder: placeholder do select
 * @param value: valor inicial do select
 * @param style: o style do item
 * }
 * Obs.: Se o componente não receber NENHUM item para ser carregado ele será desabilitado!
 */

function Select({ title = "", style, classe, ...props }) {
  return (
    <div className="dd_wrapper" style={{ ...style }}>
      <div className="title">{`${title}:`}</div>
      <ReactSelect
        className="react-select-container"
        classNamePrefix="react-select"
        components={{ MenuList }}
        {...props}
      ></ReactSelect>
    </div>
  );
}

export default Select;
