import React, { useContext, useState } from "react";
import { AnaliseContext } from "../../util/context";
import { vencimentoFormatado, format } from "../../util/analiseController";
import Select from "../../../../../../../components/UI/Select/Select";
import TemplateGeraFiltro from "../children/TemplateGeraFiltro";
import NotasSelecionadas from "./Children/NotasSelecionadas";
import TemplateResultadoContabil2 from "./Children/TemplateResultadoContabil2";
import Button from "../../../../../../../components/UI/Button/Button";
import { IntegradorContext } from "../../../../../util/context/context";
import DadosPlanilha from "../../../util/constructors/DadosPlanilha/DadosPlanilha";
import { geraTabela } from "../../../util/controller";
import { ordenaPorProximidadeVencimentoEValor } from "../../../util/constructors/Baixas/Baixas";

//TODO: might remove limit of listing
const LIMITE_MAXIMO_BAIXAS_PARA_LISTAR = 500

const MaisBaixas = () => {
  //Context
  const {
    data,
    historico,
    entrada,
    saida,
    baixasEncontradas,
    qualPromessa,
    closeModal,
  } = useContext(AnaliseContext);
  const {
    excelDados,
    adicionaItensTabela,
    despachante,
    baixas,
    setSeparador,
    setLinhaSelecionada,
  } = useContext(IntegradorContext);

  const [counter, setCounter] = useState(
    transformaEmDuasCasasDecimais(entrada) ||
    transformaEmDuasCasasDecimais(saida)
  );
  const [baixasSelecionadas, setBaixasSelecionadas] = useState([]);



  let baixasValidas = baixasEncontradas.length ? baixasEncontradas : entrada ? baixas.todasBaixasEmAbertoSaidaServico : baixas.todasBaixasEmAbertoEntrada
  baixasValidas = ordenaPorProximidadeVencimentoEValor(baixasValidas, data, entrada || saida)
  baixasValidas = baixasValidas.slice(0, LIMITE_MAXIMO_BAIXAS_PARA_LISTAR)

  console.log("baixas listadas", baixasValidas);


  const listaNotas =
    baixasValidas &&
    Array.isArray(baixasValidas) &&
    baixasValidas.map((nota, index) => {
      const { documento, vlconsiderado, vencimento, parcela } = nota;

      let value = `${index}|${vlconsiderado}`;

      let label = `Nota: ${documento} | Valor: ${format(
        vlconsiderado
      )} | DT Vencimento: ${vencimentoFormatado(
        vencimento
      )} | Parcela: ${parcela}`;

      return { value, label };
    });

  const onChangeHandler = (valorSelecionado) => {
    let index = valorSelecionado.value.split("|")[0];
    let baixas = [...baixasSelecionadas];
    baixas.push(baixasValidas[index]);

    let valor = valorSelecionado.value.split("|")[1];
    let novoCounter = transformaEmDuasCasasDecimais(
      counter - parseFloat(valor)
    );

    if (novoCounter < 0) {
      alert("Num podi");
    } else {
      setBaixasSelecionadas(baixas);
      setCounter(novoCounter);
    }
  };

  const render = (value) => {
    if (value === "contabil")
      return (
        <TemplateResultadoContabil2
          baixasSelecionadas={baixasSelecionadas}
          counter={counter}
          setBaixasSelecionadas={setBaixasSelecionadas}
          setCounter={setCounter}
        />
      );
  };

  return (
    <div className="maisBaixas_container">
      <div className="counter_valorTotal">Valor Total = R$ {counter}</div>
      <NotasSelecionadas
        baixasSelecionadas={baixasSelecionadas}
        counter={counter}
        setCounter={setCounter}
        setBaixasSelecionadas={setBaixasSelecionadas}
      />
      <div className="notasDescobertas_container">
        <Select
          options={listaNotas}
          title="Notas Encontradas"
          placeholder="Selecione a conta!"
          onChange={(valorSelecionado) => {
            onChangeHandler(valorSelecionado);
          }}
        />
      </div>

      <div className="input_container_padding">
        <TemplateGeraFiltro render={render} />
      </div>

      <Button
        classe="salvar-baixas-btn"
        clicked={() => {
          if (counter === "0") {
            let novosItens = baixasSelecionadas.map((baixa, index) => {
              if (index > 0)
                excelDados.insertRow(1, parseInt(qualPromessa) + (index - 1));
              excelDados.setRowData(parseInt(qualPromessa) + index, [
                data,
                historico,
                baixa.documento,
                entrada ? baixa.vlconsiderado : "",
                saida ? baixa.vlconsiderado : "",
              ]);

              const novoItem = new DadosPlanilha(baixas.configuracoes);
              novoItem.transformaDadosPlanilhaEmObjeto([
                data,
                historico,
                baixa.documento,
                entrada ? baixa.vlconsiderado : "",
                saida ? baixa.vlconsiderado : "",
                "",
                "",
              ]);
              novoItem.selecionaBaixa(baixa);
              return novoItem;
            });

            adicionaItensTabela(novosItens, qualPromessa);

            despachante({
              tabelaDados: geraTabela(
                baixas,
                setSeparador,
                setLinhaSelecionada
              ),
            });

            localStorage.setItem("Integrador", JSON.stringify(baixas));
            closeModal();
          }
        }}
      >
        Salvar Baixas
      </Button>
    </div>
  );
};

export default MaisBaixas;

const transformaEmDuasCasasDecimais = (num) => {
  let valorDuasCasas = Math.round(parseFloat(num) * 100) / 100;

  if (isNaN(valorDuasCasas.toString())) {
    return "";
  }

  return valorDuasCasas.toString();
};
