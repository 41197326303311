import React, { useContext } from "react";
//import { handleChangeInExcelGrid } from "./util/controller";
import { IntegradorContext } from "../../../../containers/Integrador/util/context/context";
import ExcelGrid from "../../../../components/UI/ExcelGrid/ExcelGrid";

const Planilha = () => {
  //Context
  const {
    excelDados,
    despachante,

    contaContabilSelecionada,
  } = useContext(IntegradorContext);

  //Variáveis
  let data = [[]];

  if (excelDados) data = excelDados.getData();
  if (localStorage.getItem("Integrador")) {
    const objeto = JSON.parse(localStorage.getItem("Integrador"));
    data = objeto.planilhaExcel;
  }

  const configuracaoGrid = {
    data: data,
    columns: [
      { title: "Data", width: 120 },
      { title: "Histórico", width: 300 },
      { title: "Documento", width: 120 },
      { title: "Entrada", width: 120 },
      { title: "Saida", width: 120 },
      { title: "Desconto", width: 120 },
      { title: "Juros", width: 120 },
    ],
    minDimensions: [5, 1],
  };

  return (
    <>
      <div className="planilhaContainer">
        <button
          onClick={() => {
            excelDados.setData([[]]);
            localStorage.removeItem("Integrador");
          }}
        >
          RESETAR
        </button>
        <ExcelGrid configuracao={configuracaoGrid} setGrid={despachante} />
      </div>
    </>
  );
};

export default Planilha;
