import React from "react";

import {
  faTimes,
  faCheck,
  faExclamation,
  faSpinner,
  faWindowMinimize,
  faFilter,
  faCopy,
  faTimesCircle,
  faCheckCircle,
  faEdit,
  faUserEdit,
  faTrash,
  faPlus,
  faMinus,
  faExclamationCircle,
  faExclamationTriangle,
  faLifeRing,
  faPencilAlt,
  faAngleDoubleUp,
  faCaretDown,
  faCaretUp,
  faLongArrowAltLeft,
  faExternalLinkAlt,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleRight,
  faAngleLeft,
  faCaretLeft,
  faCaretRight,
  faDownload,
  faUndo,
  faQuestionCircle,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Icone = ({ icone, ...props }) => {
  let icon = null;

  switch (icone) {
    case "faCaretLeft":
      icon = faCaretLeft;
      break;
    case "faUndo":
      icon = faUndo;
      break;
    case "faCaretRight":
      icon = faCaretRight;
      break;
    case "faAngleDoubleRight":
      icon = faAngleDoubleRight;
      break;
    case "faAngleRight":
      icon = faAngleRight;
      break;
    case "faAngleLeft":
      icon = faAngleLeft;
      break;
    case "faAngleDoubleLeft":
      icon = faAngleDoubleLeft;
      break;
    case "faMinus":
      icon = faMinus;
      break;
    case "faAngleDoubleUp":
      icon = faAngleDoubleUp;
      break;
    case "faTimes":
      icon = faTimes;
      break;
    case "faTimesCircle":
      icon = faTimesCircle;
      break;
    case "faCheck":
      icon = faCheck;
      break;
    case "faExclamation":
      icon = faExclamation;
      break;
    case "faSpinner":
      icon = faSpinner;
      break;
    case "faWindowMinimize":
      icon = faWindowMinimize;
      break;
    case "faFilter":
      icon = faFilter;
      break;
    case "faCopy":
      icon = faCopy;
      break;
    case "faCheckCircle":
      icon = faCheckCircle;
      break;
    case "faEdit":
      icon = faEdit;
      break;
    case "faUserEdit":
      icon = faUserEdit;
      break;
    case "faTrash":
      icon = faTrash;
      break;
    case "faPlus":
      icon = faPlus;
      break;
    case "faExclamationCircle":
      icon = faExclamationCircle;
      break;
    case "faExclamationTriangle":
      icon = faExclamationTriangle;
      break;
    case "falifering":
      icon = faLifeRing;
      break;
    case "faPencilAlt":
      icon = faPencilAlt;
      break;
    case "faCaretUp":
      icon = faCaretUp;
      break;
    case "faCaretDown":
      icon = faCaretDown;
      break;
    case "faLongArrowAltLeft":
      icon = faLongArrowAltLeft;
      break;
    case "faExternalLinkAlt":
      icon = faExternalLinkAlt;
      break;
    case "faDownload":
      icon = faDownload;
      break;
    case "faQuestionCircle":
      icon = faQuestionCircle;
      break;
    case "faFilePdf":
      icon = faFilePdf;
      break;
    default:
      break;
  }

  return (
    <>
      <FontAwesomeIcon icon={icon} {...props} />
    </>
  );
};

export default Icone;
