import React, { useContext, useEffect } from "react";
import { TabelaContext } from "../../../utils/context";
import { defineScroll } from "../../../utils/controller";

const ListagemPaginacao = () => {
  const {
    paginaAtual,
    despachante,
    scrollInicio,
    scrollFim,
    ultimaPagina,
    destrava,
    despachantePai,

    paginationFilter,
  } = useContext(TabelaContext);

  /*const ultimaPagina = Math.floor(
    rowsFiltrados.length / quantidadeElementosPorPagina
  );*/

  useEffect(() => {
    if (!destrava) {
      const scroll = defineScroll(
        paginaAtual,
        scrollInicio,
        scrollFim,
        ultimaPagina
      );

      despachante({ ...scroll });
    }

    if (destrava) {
      const scroll = defineScroll(0, scrollInicio, scrollFim, ultimaPagina);

      despachante({ ...scroll, paginaAtual: 0 });

      despachantePai({ destrava: false });
    }
  }, [ultimaPagina]);

  const handleOnClick = (paginaDestino) => {
    const scroll = defineScroll(
      paginaDestino,
      scrollInicio,
      scrollFim,
      ultimaPagina
    );

    despachante({ paginaAtual: paginaDestino, ...scroll });
  };

  let numerosPaginas = [];

  for (let index = scrollInicio; index <= scrollFim; index++) {
    numerosPaginas.push(
      <li
        key={index + 1}
        onClick={() => handleOnClick(index)}
        className={index === paginaAtual ? "active" : null}
      >
        {index + 1}
      </li>
    );
  }

  return (
    <>
      {paginationFilter && (
        <ul className="listagem-paginacao-teste">{numerosPaginas}</ul>
      )}
    </>
  );
};

export default ListagemPaginacao;
