import React from "react";
import { PropTypes } from "prop-types";

/**
 * @param props {
 * @param label: string
 * @param clicked: Função on-click do checkbox
 * @param checked: Boolean
 * }
 */

const Checkbox = ({ label, clicked, checked }) => {
  return (
    <div className="container_checkBox">
      <div className="checkBox-group">
        <input
          type="checkbox"
          className="checkBox-input"
          onClick={clicked}
          checked={checked}
          id={label}
          name={label}
        />
        <label for={label} className="checkBox-label">
          <span className="checkBox-button" />
          {label}
        </label>
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  clicked: PropTypes.func,
  checked: PropTypes.bool,
};

export default Checkbox;
