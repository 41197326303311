export default class BaixaSelecionada {
  constructor(configuracoes, valorDoPagamento, valorDaNota) {
    this.configuracoes = configuracoes;
    this.valorDoPagamento = parseFloat(valorDoPagamento);
    this.valorDaNota = valorDaNota;
    this.rota = null;
  }

  espalhaProps(props) {
    for (const key in props) {
      this[key] = props[key];
    }
  }

  defineRotaParaBaixa() {

    const unsafeDiferenca = this.valorDoPagamento - this.valorDaNota;
    const diferenca = isNaN(unsafeDiferenca) ? unsafeDiferenca : Number.parseFloat(Number(unsafeDiferenca).toFixed(2));

    const { padraoBaixaParcial } = this.configuracoes;

    if (diferenca === 0) {
      this.rota = "";
      return;
    }

    if (padraoBaixaParcial && diferenca < 0) {
      this.rota = "comBaixaParcial";
      return;
    }

    if (diferenca > 0) {
      this.rota = "comJuros";
      return;
    }

    if (diferenca < 0) {
      this.rota = "comDesconto";
    }
  }
}
