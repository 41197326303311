import React from "react";

import TemplateResultados from "./children/TemplateResultados";
import TemplateResultadoContabil from "../children/TemplateResultadoContabil";
import TemplateGeraFiltro from "../children/TemplateGeraFiltro";

const UmaBaixa = () => {
  const render = (value) => {
    if (value === "contabil") return <TemplateResultadoContabil />;
    return <TemplateResultados />;
  };

  return (
    <div className="input_container_padding">
      <TemplateGeraFiltro render={render} />
    </div>
  );
};

export default UmaBaixa;
