import { createGlobalStyle } from "styled-components";
export const GlobalStyles = createGlobalStyle`

  body,
  .containerViewport,
  .meioPrincipal {
background: ${({ theme }) => theme.bodyBackground};
transition: all 0.50s linear;
  }

  p,
  .dd_wrapper .title {
color: ${({ theme }) => theme.textHeader};
transition: all 0.50s linear;
  }

  .toolbar,
  .menuHorizontal nav {
background: ${({ theme }) => theme.header};
transition: all 0.50s linear;
  }

  textarea {
color: ${({ theme }) => theme.textPrimaryImportant};
background: ${({ theme }) => theme.backgroundTextareaImportant};
transition: all 0.50s linear;
  }

  .react-datetime-picker__inputGroup__divider {
color: ${({ theme }) => theme.textPrimaryImportant};
transition: all 0.50s linear;
  }
  
  .react-select__control--is-disabled {
background: ${({ theme }) => theme.selectDisabledBackground};
transition: all 0.50s linear;
  }

  input,
  .react-datetime-picker__wrapper,
  .react-select__control:not(.react-select__control--is-disabled),
  .homologacaoSelecionada_labelSegmentosConteiner{
   background: ${({ theme }) => theme.cardBackground};
   color: ${({ theme }) => theme.textPrimaryImportant};
   outline-color: ${({ theme }) => theme.inputOutlineColorImportant};
  }
   .modalNfeNfce_labelContainerDados{
background: ${({ theme }) => theme.cardBackground};
color: ${({ theme }) => theme.textPrimaryImportant};
outline-color: ${({ theme }) => theme.inputOutlineColorImportant};
transition: all 0.50s linear;
   }
   .modalNfse_textarea{
background: #d3d3d3!important;
color: #000!important;
   }

  input::-webkit-input-placeholder {
color: ${({ theme }) => theme.selectPlaceholderImportant};
transition: all 0.50s linear;
  }
  input::-moz-placeholder {
color: ${({ theme }) => theme.selectPlaceholderImportant};
transition: all 0.50s linear;
  }
  input:-ms-input-placeholder {
color: ${({ theme }) => theme.selectPlaceholderImportant};
transition: all 0.50s linear;
  }
  input:-moz-placeholder {
color: ${({ theme }) => theme.selectPlaceholderImportant};
transition: all 0.50s linear;
  }

  .react-select__placeholder {
color: ${({ theme }) => theme.selectPlaceholderImportant};
transition: all 0.50s linear;
  }

  .react-select__menu, .react-select__menu-list, .react-select__option {
background: ${({ theme }) => theme.selectBackground};
transition: all 0.50s linear;
  }

  .react-select__single-value {
color: ${({ theme }) => theme.textPrimaryImportant};
transition: all 0.50s linear;
  }

  .menuHorizontal a:hover,
  .menuHorizontal a:focus,
  .menuInferiorContainer .aba:hover,
  .menuInferiorContainer .selected span,
  .menuHorizontal nav .menu-active,
  .tabelaReact__PaginacaoRange,
  .container_CabecalhoEnviarArquivos span.total,
  .container_CabecalhoEnviarArquivos span.sucesso,
  .container_CabecalhoEnviarArquivos span.falha {
color: ${({ theme }) => theme.textPrimary};
  }

  .containerMenu {
background: ${({ theme }) => theme.dropDownMenuBackground};
color: ${({ theme }) => theme.dropDownMenuText};
transition: all 0.50s linear;
  }

  .containerMenu a {
color: ${({ theme }) => theme.dropDownMenuText};
transition: all 0.50s linear;
  }
  
  div.card,
  .react-tabs__tab-panel,
  .react-tabs__tab-panel--selected,
  .react-tabs__tab--selected,
  .modal_container {
background: ${({ theme }) => theme.cardBackground};
color: ${({ theme }) => theme.cardText};
transition: all 0.50s linear;
  }

  .container_dropZone .dropzone {
background: ${({ theme }) => theme.dropZoneBackgroundImportant};
transition: all 0.50s linear;
  }

  .container_dropZone .dropzone,
  .container_dropZone .dropzone p {
color: ${({ theme }) => theme.dropZoneText};
transition: all 0.50s linear;
  }

  .container_dropZone .dropzone:hover,
  .container_dropZone .dropzone:hover p {
color: ${({ theme }) => theme.dropZoneTextHover};
transition: all 0.50s linear;
  }

  .react-datetime-picker__inputGroup__divider {
color: ${({ theme }) => theme.dateTimeSeparador};
transition: all 0.50s linear;
  }

  .tableRows_word {
color: ${({ theme }) => theme.textPrimaryImportant};
transition: all 0.50s linear;
  }

  .input_container__wrapper,
  .input_container__wrapper--input {
color: ${({ theme }) => theme.inputText};
transition: all 0.50s linear;
  }

  .input_container__label,
  .checkbox_historico.label:first-child {
color: ${({ theme }) => theme.inputLabel};
transition: all 0.50s linear;
  }

  table th {
color: ${({ theme }) => theme.tableColor};
transition: all 0.50s linear;
  }

  .tabelaReact__PaginacaoListagem ul li {
color: ${({ theme }) => theme.tabelaReactCorImportant};
transition: all 0.50s linear;
  }

  .tabelaReact__Container thead th {
background: ${({ theme }) => theme.tabelaReactBackgroundTH};
transition: all 0.50s linear;
  }

  .tabelaReact__Container thead, .tabelaReact__Container tbody {
border-color: ${({ theme }) => theme.tabelaReactBorderColor};
transition: all 0.50s linear;
  }  

  .tabelaReact__Container tr td {
background: ${({ theme }) => theme.tabelaReactBackground};
color: ${({ theme }) => theme.textPrimaryImportant};
border-color: ${({ theme }) => theme.tabelaReactBorderColor};
transition: all 0.50s linear;
  }

  .tabelaReact__Container tr:nth-child(even) td {
background: ${({ theme }) => theme.tabelaReactBackgroundEven};
transition: all 0.50s linear;
  }

  .informacoes p:first-child {
color: ${({ theme }) => theme.textPrimary};
transition: all 0.50s linear;
  }

  .informacoes p:nth-child(2),
  .informacoes p:nth-child(3) {
color: ${({ theme }) => theme.conferirArquivosTextColor};
transition: all 0.50s linear;
  }

  .integrador_tabs .react-tabs__tab-list li {
background: ${({ theme }) => theme.integradorTabsBackground};
transition: all 0.50s linear;
  }

  .integrador_tabs .react-tabs__tab-list .react-tabs__tab--disabled {
background: ${({ theme }) => theme.integradorTabsDisabledBackground};
color: ${({ theme }) => theme.integradorTabsDisabledColor};
transition: all 0.50s linear;
  }

  .integradorToggleContainer__box h1,
  .integradorToggleContainer__box p {
color: ${({ theme }) => theme.textPrimary};
transition: all 0.50s linear;
  }

  .integradorToggleContainer__box {
box-shadow: ${({ theme }) => theme.integradorToggleShadow};
transition: all 0.50s linear;
  }

  .integracao_container table .blue {
color: ${({ theme }) => theme.integradorSpanColorBlue};
transition: all 0.50s linear;
  }

  .integracao_container table .red {
color: ${({ theme }) => theme.integradorSpanColorRed};
transition: all 0.50s linear;
  }

  .integracao_container table .purple {
color: ${({ theme }) => theme.integradorSpanColorPurple};
transition: all 0.50s linear;
  }

  .integracao_container table .green {
color: ${({ theme }) => theme.integradorSpanColorGreen};
transition: all 0.50s linear;
  }

  .integracao_container table .table-body tr:hover {
background-color: ${({ theme }) => theme.integradorTableHoverBackground};
transition: all 0.50s linear;
  }

  .integradorBaixasContainer_tabs {
background-color: ${({ theme }) => theme.integradorTabsBackground};
transition: all 0.50s linear;
  }

  .analiseNotasContainer__aviso p {
color: ${({ theme }) => theme.textPrimary};
transition: all 0.50s linear;
  }

  .integradorBaixa_button_tabs {
background-color: ${({ theme }) => theme.analiseTabsBackground};
color: ${({ theme }) => theme.analiseTabsColor};
transition: all 0.50s linear;
  }

  .separadorTable thead th,
  .separadorTable tfoot th {
color: ${({ theme }) => theme.textPrimary};
transition: all 0.50s linear;
  }

  .separadorTable tbody tr td {
color: ${({ theme }) => theme.separadorTableColor};
transition: all 0.50s linear;
  }

  .opcoes_container .alerta {
color: ${({ theme }) => theme.textPrimary};
text-shadow: ${({ theme }) => theme.integradorOpcoesAlertTextShadow};
transition: all 0.50s linear;
  }

  .relatorios_metabase_rotulo {
background-color: ${({ theme }) => theme.cardBackground};
transition: all 0.50s linear;
  }

  .cardTabelaXml_buttonIcons {
color: ${({ theme }) => theme.textPrimaryImportant};
transition: all 0.50s linear;
  }

  .cardTabelaXml_buttonsLabelTitle img {
filter: ${({ theme }) => theme.textPrimaryImportant};
transition: all 0.50s linear;
  }
`;
