import React, { useContext } from "react";
import { TabelaContext } from "../../utils/context";
import { defineRange } from "../../utils/controller";

const Indicador = () => {
  const {
    paginaAtual,
    quantidadeElementosPorPagina,
    rowsFiltrados,
    paginationFilter,
  } = useContext(TabelaContext);

  const [rangeIncio, rangeFim] = defineRange(
    paginaAtual,
    quantidadeElementosPorPagina
  );

  return (
    <>
      {paginationFilter && (
        <span className="tabelaReact__PaginacaoRange texto-entradas-teste">
          Mostrando {rangeIncio + 1} a{" "}
          {rangeFim > rowsFiltrados.length ? rowsFiltrados.length : rangeFim} de{" "}
          {rowsFiltrados.length} entradas
        </span>
      )}
    </>
  );
};

export default Indicador;
