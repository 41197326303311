import React, { useEffect, useContext, useState } from "react";
import useInput from "../../../../../helpers/customHooks/useInput";
import { TabelaContext } from "../../utils/context";
import { defineScroll } from "../../utils/controller";
const SearchBox = ({ rowsPai }) => {
  const {
    despachante,
    rows,
    quantidadeElementosPorPagina,
    search,
    despachantePai,
    searchFilter,
  } = useContext(TabelaContext);
  const [rowsFixo, setRowsFixo] = useState(rows);

  const [valorInput, componenteReactInput] = useInput(
    "Pesquisar",
    "",
    "pesquisar",
    search,
    "text"
  );

  useEffect(() => {
    const rowsFiltradas = pesquisarArray(valorInput, rowsFixo);
    const ultimaPagina = Math.floor(
      rowsFiltradas.length / quantidadeElementosPorPagina
    );

    const scroll = defineScroll(0, 0, 4, ultimaPagina);
    despachantePai({ search: valorInput });
    despachante({
      rowsFiltrados: rowsFiltradas,
      paginaAtual: 0,
      ...scroll,
      search: null,
      ultimaPagina: ultimaPagina,
    });
  }, [valorInput]);

  useEffect(() => {
    const ultimaPagina = Math.floor(rows.length / quantidadeElementosPorPagina);

    setRowsFixo(rows);

    let rowsDespachado = rows;

    if (valorInput) rowsDespachado = pesquisarArray(valorInput, rowsPai);

    despachante({ rowsFiltrados: rowsDespachado, ultimaPagina: ultimaPagina });
  }, [rows]);

  const limparTexto = (string) => {
    let textoLimpo = string.toLowerCase();
    return textoLimpo.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const pesquisarArray = (valor, quaisRows) => {
    let filtro = quaisRows.filter(
      (row) => limparTexto(row.join("")).indexOf(limparTexto(valor)) > -1
    );
    if (filtro.length > 0) {
      return filtro;
    }
    return [["Nenhum dado encontrado."]];
  };

  return (
    <>
      {searchFilter && (
        <div className="tabelaReact__SearchBox">{componenteReactInput} </div>
      )}
    </>
  );
};

export default SearchBox;
