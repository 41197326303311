import React from "react";
import Icone from "../../../../../../../../components/UI/Icone/Icone";
import { format, vencimentoFormatado } from "../../../util/analiseController";

const NotasSelecionadas = ({
  baixasSelecionadas,
  counter,
  setCounter,
  setBaixasSelecionadas,
}) => {
  return (
    <div className="notasSelecionadas_container">
      {baixasSelecionadas.map((baixa, index) => {
        const { documento, vlconsiderado, vencimento, parcela } = baixa;
        return (
          <div className="boxDeNotas" key={index}>
            <span className="info">
              Nota: <p className="dados">{documento}</p><span className="divisoria">|</span>
              Valor: <p className="dados">{format(vlconsiderado)}</p><span className="divisoria">|</span>
              DT Vencimento: <p className="dados">{vencimentoFormatado(vencimento)}</p><span className="divisoria">|</span>
              Parcela: <p className="dados">{parcela}</p>
            </span>
            <span className="icon-container">
              <Icone
                icone="faTimes"
                size="1x"
                onClick={() => {
                  let novoCounter =
                    parseFloat(counter) + parseFloat(vlconsiderado);
                  setCounter(novoCounter);

                  let baixas = [...baixasSelecionadas];
                  baixas.splice(index, 1);
                  setBaixasSelecionadas(baixas);
                }}
                title="Remover Palavra"
              />
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default NotasSelecionadas;
