import {
  getBaixas,
  getOpcoesPeloHistorico,
} from "../../../../../../../helpers/requisicoesHttp/integradorRequisicoes";

export const requisicaoHistorico = async (
  pHistorico,
  codigoEmpresa,
  codigoContaContabil
) => {
  const opcaoEncontrada = await getOpcoesPeloHistorico(
    codigoEmpresa,
    codigoContaContabil,
    pHistorico
  );

  if (opcaoEncontrada) {
    const { ct_partida, codigo, historico, sb_historico } = opcaoEncontrada;

    return [
      {
        contraPartida: {
          value: ct_partida,
          label: "Achado por configuração prévia do usuário",
        },
        codigoDoHistorico: codigo.toString(),
        historicoValue: sb_historico ? historico : pHistorico,
        tickColor: "green",
        tickIcon: "faCheck",
        operacao: 1,
      },
    ];
  }

  return null;
};

export const requisicaoEntrada = (saida, documento, codigoEmpresa) => {
  let queryEntrada;

  if (!requisicaoEntrada[0] && documento) {
    queryEntrada = geraQuery("entrada", null, documento);
    return getBaixas(queryEntrada, codigoEmpresa);
  }

  queryEntrada = geraQuery("entrada", saida, documento);
  return getBaixas(queryEntrada, codigoEmpresa);
};

export const requisicaoSaida = async (entrada, documento, codigoEmpresa) => {
  let querySaida;

  if (!requisicaoSaida[0] && documento) {
    querySaida = geraQuery("saida", null, documento);
    return getBaixas(querySaida, codigoEmpresa);
  }

  querySaida = geraQuery("saida", entrada, documento);
  return getBaixas(querySaida, codigoEmpresa);
};

export const requisicaoServico = async (entrada, documento, codigoEmpresa) => {
  let queryServico;

  if (!requisicaoServico[0] && documento) {
    queryServico = geraQuery("servico", null, documento);
    return getBaixas(queryServico, codigoEmpresa);
  }

  queryServico = geraQuery("servico", entrada, documento);
  return getBaixas(queryServico, codigoEmpresa);
};

const geraQuery = (requisicao, valor, documento) => {
  let query = `${requisicao}?`;

  if (!documento && valor) {
    query += `vl_doc=${valor}`;
  }

  if (documento && valor) {
    query += `vl_doc=${valor}`;
    query += `&num_doc=${documento}`;
  }

  if (documento && !valor) {
    query += `num_doc=${documento}`;
  }

  return query;
};
