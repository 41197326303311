const useStorage = (valor) => {
  const salvarValor = (dados) => {
    localStorage.setItem(valor, dados);
  };

  const valorRecuperado = () => {
    return localStorage.getItem(valor);
  };

  const deletaValor = () => {
    localStorage.removeItem(valor);
  };

  return [valorRecuperado, salvarValor, deletaValor];
};

export default useStorage;
