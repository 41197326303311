import React, { useState, useContext, useEffect } from "react";
import Button from "../../../../../components/UI/Button/Button";
import Input from "../../../../../components/UI/Input/Input";
import Select from "../../../../../components/UI/Select/Select";
import { IntegradorContext } from "../../../util/context/context";
import Checkbox from "../../../../../components/UI/Checkbox/Checkbox";
import { notify } from "../../../../../components/UI/Notificacao/Notificacao";
import {
   atualizaOpcao,
   atualizaParteHistorico,
   deletaOpcao,
   buscaHistoricos,
   salvaHistorico,
   getHistorico,
} from "../../../../../helpers/requisicoesHttp/integradorRequisicoes";
import updateOpcoes from "./util/controller";
import Icone from "../../../../../components/UI/Icone/Icone";

const EditarOpcao = () => {
   const {
      opcaoSelecionada,
      opcaoSelecionadaBanco,
      listaContas,
      listaHistoricos,
      despachante,
      filtroHandler,
      checkboxSim,
      checkboxNao,
   } = useContext(IntegradorContext);

   useEffect(() => {
      (async function fetch() {
         try {
            const dadoslistaHistoricos = await buscaHistoricos();
            filtroHandler({ listaHistoricos: dadoslistaHistoricos });
         } catch (error) {
            console.log(error);
         }
      })();
      if (opcaoSelecionada[5] === "Sim") {
         despachante({ checkboxSim: true, checkboxNao: false });
      } else {
         despachante({ checkboxNao: true, checkboxSim: false });
      }
   }, []);

   // Input Editar : Parte do Historico //
   const [novaParteDoHistorico, setNovaParteDoHistorico] = useState(
      opcaoSelecionada[1]
   );
   const parteDoHistoricoInput = (valueInputParteDoHistorico) => {
      try {
         setNovaParteDoHistorico(valueInputParteDoHistorico.target.value);
      } catch (error) {
         console.log(error);
      }
   };
   const inputEditarParteDoHistoricoProps = {
      value: novaParteDoHistorico,
      inputtype: "text",
      label: "Parte do Histórico",
      placeholder: "Digite a Parte do Histórico",
      name: "Parte_do_Historico",
      onChangeFunc: parteDoHistoricoInput,
   };
   //

   // Select Editar : Contra Partida //
   const [novaContraPartida, setNovaContraPartida] = useState({
      value: opcaoSelecionada[3],
      label: opcaoSelecionada[3],
   });
   const contraPartidaSelect = async (valueSelectContraPartida) => {
      try {
         setNovaContraPartida(valueSelectContraPartida);
      } catch (error) {
         console.log(error);
      }
   };
   const selectContraPartidaProps = {
      title: "Contra Partida",
      value: novaContraPartida,
      options: listaContas,
      placeholder: "Selecione a Contra Partida",
      isClearable: true,
      isSearchable: true,
      onChange: contraPartidaSelect,
   };
   //

   // Select Editar : Historico //
   const [novoHistoricoExistente, setNovoHistoricoExistente] = useState({
      value: opcaoSelecionadaBanco.historico_id,
      label: opcaoSelecionada[4],
   });
   const historicoSelect = async (valueSelectHistorico) => {
      try {
         setNovoHistoricoExistente(valueSelectHistorico);
      } catch (error) {
         console.log(error);
      }
   };
   const selectHistoricoProps = {
      title: "Histórico",
      value: novoHistoricoExistente,
      options: listaHistoricos,
      placeholder: "Selecione o Histórico",
      isClearable: true,
      isSearchable: true,
      onChange: historicoSelect,
   };
   //

   // CheckBox Sobrescrever Histórico : Sim //
   const checkboxSimProps = {
      label: "Sim",
      clicked: () => {
         despachante({ checkboxSim: true, checkboxNao: false });
      },
      checked: checkboxSim,
   };
   //

   // CheckBox Sobrescrever Histórico : Nao //
   const checkboxNaoProps = {
      label: "Não",
      clicked: () => {
         despachante({ checkboxSim: false, checkboxNao: true });
      },
      checked: checkboxNao,
   };
   //

   // Input Adicionar : Novo Historico //
   const [adicionarNovoHistorico, setAdicionarNovoHistorico] = useState("");
   const novoHistoricoInput = (valueInputHistorico) => {
      try {
         setAdicionarNovoHistorico(valueInputHistorico.target.value);
      } catch (error) {
         console.log(error);
      }
   };
   const inputNovoHistoricoProps = {
      value: adicionarNovoHistorico,
      inputtype: "text",
      label: "Novo Histórico",
      placeholder: "Digite o novo Histórico a ser criado",
      name: "Novo_Historico",
      onChangeFunc: novoHistoricoInput,
   };
   //

   // Botao Salvar //
   const botaoSalvarProps = {
      children: "Salvar",
      clicked: () => {
         (async function fetch() {
            try {
               if (novaParteDoHistorico) {
                  let respostaPUT = await atualizaParteHistorico(
                     novaParteDoHistorico,
                     opcaoSelecionadaBanco.parte_historico_id
                  );
                  if (respostaPUT.parte_historico === novaParteDoHistorico) {
                     opcaoSelecionada[1] = respostaPUT.parte_historico;
                     despachante({ modalOpcao: false });
                  }
               }
               if (
                  novaContraPartida ||
                  novoHistoricoExistente ||
                  checkboxSim ||
                  checkboxNao
               ) {
                  const objetoQueAtualizaTabela = new updateOpcoes();
                  objetoQueAtualizaTabela.geraObjeto(
                     opcaoSelecionadaBanco,
                     novaContraPartida,
                     novoHistoricoExistente,
                     checkboxSim
                  );
                  let respostaPUT = await atualizaOpcao(
                     objetoQueAtualizaTabela,
                     opcaoSelecionadaBanco.id
                  );
                  if (respostaPUT.ct_partida === novaContraPartida.value) {
                     opcaoSelecionada[3] = novaContraPartida.value;
                     despachante({ modalOpcao: false });
                  }
                  if (
                     respostaPUT.historico_id === novoHistoricoExistente.value
                  ) {
                     opcaoSelecionada[4] = novoHistoricoExistente.label;
                     despachante({ modalOpcao: false });
                  }
                  if (respostaPUT.sb_historico === 1) {
                     opcaoSelecionada[5] = "Sim";
                     despachante({ checkboxSim: true, modalOpcao: false });
                  }
                  if (respostaPUT.sb_historico === 0) {
                     opcaoSelecionada[5] = "Não";
                     despachante({ checkboxNao: true, modalOpcao: false });
                  }
               }
               notify("Opção atualizada!", "aviso");
               if (adicionarNovoHistorico) {
                  let respostaPOST = await salvaHistorico(
                     adicionarNovoHistorico
                  );
                  let respostaGET = await getHistorico(respostaPOST);
                  if (respostaGET.historico === adicionarNovoHistorico) {
                     const objetoQueAtualizaTabela2 = new updateOpcoes();
                     objetoQueAtualizaTabela2.geraObjeto2(
                        opcaoSelecionadaBanco,
                        novaContraPartida,
                        respostaPOST,
                        checkboxSim
                     );
                     await atualizaOpcao(
                        objetoQueAtualizaTabela2,
                        opcaoSelecionadaBanco.id
                     );
                     opcaoSelecionada[4] = respostaGET.historico;
                     despachante({ modalOpcao: false });
                  }
               }
            } catch (error) {
               console.log(error);
            }
         })();
      },
      btnType: "acao",
   };
   //

   // Botao (+) Criar Historico //
   const [criarHistoricoButton, setCriarHistoricoButton] = useState(false);
   const botaoCriarHistorico = {
      children: criarHistoricoButton ? <Icone icone="faMinus" size="1x" /> : <Icone icone="faPlus" size="1x" />,
      classe: "historico_plus",
      clicked: () => {
         setCriarHistoricoButton(!criarHistoricoButton);
      },
      btnType: "acao",
   };
   //

   // Botao Deletar //
   const botaoExcluirProps = {
      children: "Excluir Opção",
      clicked: () => {
         var confirmacaoParaExcluir = window.confirm(
            "Deseja excluir a opção selecionada?"
         );
         if (confirmacaoParaExcluir === true) {
            (async function fetch() {
               try {
                  notify("Opção excluída!", "aviso");
                  await deletaOpcao(opcaoSelecionadaBanco.id);
                  let deletarOpcao = (opcaoSelecionada.length = 0);
                  despachante({
                     opcaoSelecionada: deletarOpcao,
                     modalOpcao: false,
                  });
               } catch (error) {
                  console.log(error);
               }
            })();
         }
      },
      btnType: "cancelar",
   };
   //

   return (
      <>
         <div>
            <Input {...inputEditarParteDoHistoricoProps} />
         </div>
         <div>
            <Select {...selectContraPartidaProps} />
         </div>
         <div className="historico_com_botao_adicionar">
            {criarHistoricoButton === true && (
               <Input {...inputNovoHistoricoProps} />
            )}
            {criarHistoricoButton === false && (
               <Select {...selectHistoricoProps} />
            )}
            <Button {...botaoCriarHistorico} />
         </div>
         <div className="checkbox_historico">
            <label>Sobrescrever Histórico:</label>
            <Checkbox {...checkboxSimProps} />
            <Checkbox {...checkboxNaoProps} />
         </div>
         <div className="editarOpcao_botoes">
            <Button {...botaoSalvarProps} />
            <Button {...botaoExcluirProps} />
         </div>
      </>
   );
};

export default EditarOpcao;
