/* eslint-disable */
export default class updateOpcoes {
   constructor() {
      this.baixa_parcial = "";
      this.codi_conta = "";
      this.codi_emp = "";
      this.codigo = "";
      this.ct_partida = "";
      this.historico = "";
      this.historico_id = "";
      this.id = "";
      this.operacao = "";
      this.operacao_id = "";
      this.parte_historico = "";
      this.parte_historico_id = "";
      this.sb_historico = "";
   }

   geraObjeto(opcaoSelecionadaBanco, novaContraPartida, novoHistoricoExistente, checkboxSim){
      this.baixa_parcial = opcaoSelecionadaBanco.baixa_parcial,
      this.codi_conta = opcaoSelecionadaBanco.codi_conta,
      this.codi_emp = opcaoSelecionadaBanco.codi_emp,
      this.codigo = opcaoSelecionadaBanco.codigo,
      this.ct_partida = (novaContraPartida === null || "") || (novaContraPartida.value === undefined) ? opcaoSelecionadaBanco.ct_partida : novaContraPartida.value,
      this.historico = opcaoSelecionadaBanco.historico,
      this.historico_id = (novoHistoricoExistente === null || "") || (novoHistoricoExistente.value === undefined) ? opcaoSelecionadaBanco.historico_id === 0 ? null : opcaoSelecionadaBanco.historico_id : novoHistoricoExistente.value,
      this.id = opcaoSelecionadaBanco.id,
      this.operacao = opcaoSelecionadaBanco.operacao,
      this.operacao_id = opcaoSelecionadaBanco.operacao_id,
      this.parte_historico = opcaoSelecionadaBanco.parte_historico,
      this.parte_historico_id = opcaoSelecionadaBanco.parte_historico_id,
      this.sb_historico = checkboxSim === true ? 1 : 0
   }

   geraObjeto2(opcaoSelecionadaBanco, novaContraPartida, novoHistorico, checkboxSim){
      this.baixa_parcial = opcaoSelecionadaBanco.baixa_parcial,
      this.codi_conta = opcaoSelecionadaBanco.codi_conta,
      this.codi_emp = opcaoSelecionadaBanco.codi_emp,
      this.codigo = opcaoSelecionadaBanco.codigo,
      this.ct_partida = (novaContraPartida === null || "") || (novaContraPartida.value === undefined) ? opcaoSelecionadaBanco.ct_partida : novaContraPartida.value,
      this.historico = opcaoSelecionadaBanco.historico,
      this.historico_id = (novoHistorico === null || "") ? opcaoSelecionadaBanco.historico_id === 0 ? null : opcaoSelecionadaBanco.historico_id : novoHistorico,
      this.id = opcaoSelecionadaBanco.id,
      this.operacao = opcaoSelecionadaBanco.operacao,
      this.operacao_id = opcaoSelecionadaBanco.operacao_id,
      this.parte_historico = opcaoSelecionadaBanco.parte_historico,
      this.parte_historico_id = opcaoSelecionadaBanco.parte_historico_id,
      this.sb_historico = checkboxSim === true ? 1 : 0
   }

}
