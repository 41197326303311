import React, { useState } from "react";
import Select from "../../components/UI/Select/Select";

const useSelect = (
  title,
  options,
  placeholder,
  isClearable,
  isSearchable,
  defaultValue,
  onChangeSelect
) => {
  const [value, setValue] = useState(defaultValue);
  const select = (
    <Select
      title={title}
      value={value}
      options={options}
      placeholder={placeholder}
      isClearable={isClearable}
      isSearchable={isSearchable}
      onChange={(e) => {
        setValue(e);
        if(onChangeSelect)onChangeSelect(e);
      }}
    />
  );
  return [value, select];
};

export default useSelect;
