import { createContext } from "react";

//Initial State
const initialState = {
  Id: 778,
  autenticado: true,
  Nome: "Teste",
  Banco: "Korok",
  Login: "",
  Permissao: 2,
  menuStart: "",
  listaEmpresas: [],
  loading: false,
};

//Create context
export const GlobalContext = createContext(initialState);
