import React, { useContext, useEffect } from "react";
import { TabelaContext } from "../../../utils/context";
import { defineRange } from "../../../utils/controller";

const RowsTabela = ({ rowsFixo }) => {
  const {
    onClickRow,
    icones,
    quantidadeElementosPorPagina,
    paginaAtual,
    rowsFiltrados,
    paginationFilter,
    rows,
  } = useContext(TabelaContext);

  const selecionaRow = (rowIndex, e) => {
    onClickRow(rowIndex, e);
  };

  const [rangeIncio, rangeFim] = defineRange(
    paginaAtual,
    quantidadeElementosPorPagina
  );

  const geraRows = () => {
    let rowsTemplate = rowsFiltrados;

    if (rowsFiltrados.length >= 5 && paginationFilter) {
      rowsTemplate = rowsFiltrados.slice(rangeIncio, rangeFim);
    } else if (!paginationFilter) {
      rowsTemplate = rowsFiltrados;
    }

    return rowsTemplate.map((row) => {
      let index;
      rowsFixo.forEach((rowPorPagina, ind) => {
        if (rowPorPagina === row) {
          index = ind;
        }
      });
      return (
        <tr onClick={(e) => selecionaRow(index, e)} key={index} id={index}>
          {row.map((cell, i) => {
            return <td key={i}>{cell}</td>;
          })}

          {icones}
        </tr>
      );
    });
  };

  return (
    <tbody className="tabelaReact__tabelaComponente__bodyComponente">
      {rowsFiltrados && geraRows()}
    </tbody>
  );
};

export default RowsTabela;
