//Libraries
import React, { useState, useContext } from "react";
import MenuInferior from "../../components/Navigation/MenuInferior/MenuInferior";
import { GlobalContext } from "../../contextGlobal/context";

//Children
import Opcoes from "./children/Opcoes/Opcoes";
import Planilha from "./children/Planilha/Planilha";
import Integracao from "./children/Integracao/Integracao";
import Configuracoes from "./children/Configuracoes/Configuracoes";
import Espelho from "./children/Espelho/Espelho";

//Files
import { IntegradorContext, comProvider } from "./util/context/context";

import { geraSelectEmpresa, geraSelectConta, geraSelectFornecedores, geraSelectClientes, geraSelectServicos } from "./util/controller";

//Components
import Card from "../../components/UI/Card/Card";
import Select from "../../components/UI/Select/Select";
import { notify } from "../../components/UI/Notificacao/Notificacao";
import CardWithTabs from "../../components/UI/CardWithTabs/CardWithTabs";
import useDocumentTitle from "../../helpers/customHooks/useDocumentTitle";

const Integrador = () => {
  //Context
  const { listaEmpresas, listaContas, empresaSelecionada, contaContabilSelecionada, despachante, filtroHandler } = useContext(IntegradorContext);

  //State
  const [settings, setSettings] = useState({
    titulosDasTabs: [
      { nome: "Planilha", restricao: "" },
      {
        nome: "Integração",
        restricao: () => {
          notify("Por favor escolher a Empresa e a Conta Contábil!");
        },
      },
      { nome: "Opções", restricao: "" },
      { nome: "Configurar", restricao: "" },
      { nome: "Espelho", restricao: "" },
    ],
    children: [<Planilha key={0} />, <Integracao key={0} />, <Opcoes key={0} />, <Configuracoes key={0} />, <Espelho key={0} />],
  });
  const [carregando, setCarregando] = useState(false);

  //Use effect

  React.useEffect(() => {
    (async function fetch() {
      if (!empresaSelecionada) {
        await geraSelectEmpresa(filtroHandler);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (localStorage.getItem("Integrador")) {
      let empresaCache = JSON.parse(localStorage.getItem("Integrador")).codigoEmpresa;

      empresaCache = listaEmpresas.filter((empresa) => empresa.value === empresaCache);

      if (empresaCache[0]) {
        despachante({ empresaSelecionada: empresaCache[0].value });
      }
    }
  }, [listaEmpresas]);

  React.useEffect(() => {
    if (empresaSelecionada) {
      (async function fetch() {
        setCarregando(true);
        despachante({ listaContas: [] });
        const empresa = empresaSelecionada || JSON.parse(localStorage.getItem("Integrador")).codigoEmpresa;

        await Promise.all([geraSelectFornecedores(despachante, empresa), geraSelectClientes(despachante, empresa), geraSelectServicos(despachante, empresa), geraSelectConta(filtroHandler, empresa)]);

        setCarregando(false);
      })();
    }
  }, [empresaSelecionada]);

  React.useEffect(() => {
    (async function fetch() {
      if (localStorage.getItem("Integrador")) {
        let contaCache = JSON.parse(localStorage.getItem("Integrador")).codigoContaContabil;

        contaCache = listaContas.filter((conta) => conta.value === contaCache);

        if (contaCache[0]) {
          despachante({ contaContabilSelecionada: contaCache[0].value });
        }
      }
    })();
  }, [listaContas]);

  React.useEffect(() => {
    if (empresaSelecionada) {
      setSettings((prevstate) => {
        let novoEstado = { ...prevstate };
        novoEstado.titulosDasTabs[1] = { nome: "Integração", restricao: "" };
        return novoEstado;
      });
    }
  }, [contaContabilSelecionada]);

  return (
    <>
      <Card titulo="Integrador ">
        <div className="integrador_container">
          <Select
            options={listaEmpresas}
            classe="selectEmpresasIntegrador"
            title="Empresa"
            placeholder="Selecione a empresa!"
            onChange={(valorSelecionado) => despachante({ empresaSelecionada: valorSelecionado.value })}
            value={listaEmpresas && listaEmpresas.filter((empresa) => empresa.value === empresaSelecionada)[0]}
          />
          <Select
            options={listaContas}
            title="Conta Contábil"
            placeholder={(listaContas?.length || 0) > 0 ? "Selecione a conta!" : ""}
            isLoading={carregando ? true : false}
            onChange={(valorSelecionado) => despachante({ contaContabilSelecionada: valorSelecionado.value })}
            isDisabled={(listaContas?.length || 0) > 0 ? false : true}
            value={listaContas.filter((conta) => conta.value === contaContabilSelecionada)[0]}
          />
        </div>
      </Card>
      <div className="integrador_tabs">
        <CardWithTabs {...settings}></CardWithTabs>
      </div>
    </>
  );
};

const IntegradorProv = comProvider(Integrador);

const MenuIntegrador = () => {
  useDocumentTitle("Korok - Integrador Contábil");
  const { Permissao } = useContext(GlobalContext);

  let componentes;
  componentes = [<IntegradorProv />];

  return (
    <>
      <MenuInferior componentes={componentes} />
    </>
  );
};

export default MenuIntegrador;
