export const defineRange = (pagina, quantidadeElementosPorPaginaVariavel) => {
  const rangeInicio = pagina * quantidadeElementosPorPaginaVariavel;
  const rangeFim = rangeInicio + quantidadeElementosPorPaginaVariavel;

  return [rangeInicio, rangeFim];
};

export const defineScroll = (posicao, inicio, fim, ultimaPagina) => {
  let scrollInicio = inicio;
  let scrollFim = fim;

  if (posicao === inicio && posicao !== 0) {
    scrollInicio = posicao - 4;
    scrollFim = fim === ultimaPagina ? inicio : fim - 4;
  }

  if (posicao === fim && posicao !== ultimaPagina) {
    scrollInicio = posicao;
    scrollFim = fim + 4 > ultimaPagina ? ultimaPagina : fim + 4;
  }

  if (posicao === 0 && ultimaPagina === 0) {
    scrollInicio = 0;
    scrollFim = 0;
  }

  if (posicao === 0 && ultimaPagina < fim) {
    scrollInicio = 0;
    scrollFim = ultimaPagina;
  }

  if (posicao === 0 && ultimaPagina > fim) {
    scrollInicio = 0;
    scrollFim = 4;
  }

  return { scrollInicio, scrollFim };
};
