import DadosPlanilha from "../DadosPlanilha/DadosPlanilha";
import { requisicaoHistorico, requisicaoSaida, requisicaoEntrada, requisicaoServico } from "./utils";
import pLimit from "p-limit";
import dayjs from "dayjs";
import { isArray } from "lodash";

const limit = pLimit(10);
const DEBUG = false;
const BAIXAS_LIMIT = 7000;  // to avoid localstorage limit excess

export default class Baixas {
  constructor(empresa, contaContabilSelecionada, configuracoes, planilhaExcel) {
    this.codigoEmpresa = empresa;
    this.codigoContaContabil = contaContabilSelecionada;
    this.planilhaExcel = planilhaExcel;
    this.tabela = [];
    this.analises = [];
    // salva todas baixas em aberto para serem usadas por lançamentos sem documento especificado
    this.todasBaixasEmAbertoEntrada = [];
    this.todasBaixasEmAbertoSaidaServico = [];
    this.configuracoes = configuracoes;
  }

  buscaBaixas(entrada, saida, documento) {
    if (!documento || (!entrada && !saida)) return [];
    const entradas = this.todasBaixasEmAbertoEntrada;
    const saidas = this.todasBaixasEmAbertoSaidaServico;
    if (entrada) {
      return saidas.filter((b) => b.documento === Number(String(documento).trim())
      )
    }
    return entradas.filter((b) => b.documento === Number(String(documento).trim())
    )
  }

  filtraPorDocumentosLimita(baixas, documentos) {
    const filtradas = baixas.filter((e) => documentos.includes(parseInt(e.documento)));
    if (DEBUG) console.log(`filtradas ${filtradas.length} baixas de ${baixas.length} por ${documentos.lenght} documentos`)
    const added = filtradas.map((e) => e.codigo);
    return [...filtradas, ...baixas.slice(0, BAIXAS_LIMIT).filter((e) => !added.includes(e.codigo)),]
  }

  async fazRequisicaoTodasBaixasEmAberto() {
    const listaDocumentos = this.planilhaExcel.map((e) => parseInt(e[2]));

    const baixasEntradasEmAberto = await requisicaoEntrada(0, undefined, this.codigoEmpresa);
    if (isArray(baixasEntradasEmAberto) && baixasEntradasEmAberto.length) {
      const baixasEntradasEmAbertoFiltradas = this.filtraPorDocumentosLimita(baixasEntradasEmAberto, listaDocumentos);
      this.todasBaixasEmAbertoEntrada = baixasEntradasEmAbertoFiltradas
      console.log(`baixas entrada: ${baixasEntradasEmAbertoFiltradas.length}`)
    }
    const baixasSaidaEmAberto = await requisicaoSaida(0, undefined, this.codigoEmpresa)
    if (isArray(baixasSaidaEmAberto) && baixasSaidaEmAberto.length) {
      const baixasSaidaEmAbertoFiltradas = this.filtraPorDocumentosLimita(baixasSaidaEmAberto, listaDocumentos);
      this.todasBaixasEmAbertoSaidaServico = [...this.todasBaixasEmAbertoSaidaServico, ...baixasSaidaEmAbertoFiltradas]
      console.log(`baixas saida: ${baixasSaidaEmAbertoFiltradas.length}`)
    }
    const baixasServicoEmAberto = await requisicaoServico(0, undefined, this.codigoEmpresa)
    if (isArray(baixasServicoEmAberto) && baixasServicoEmAberto.length) {
      const baixasServicoEmAbertoFiltradas = this.filtraPorDocumentosLimita(baixasServicoEmAberto, listaDocumentos);
      this.todasBaixasEmAbertoSaidaServico = [...this.todasBaixasEmAbertoSaidaServico, ...baixasServicoEmAbertoFiltradas]
      console.log(`baixas serviço: ${baixasServicoEmAbertoFiltradas.length}`)
    }
  }

  async fazRequisicoesHistorico() {
    const promessas = [];
    let analisesHistorico = this.analises;
    for (let i = 0; i < this.analises.length; i++) {
      const index = this.analises[i];
      const dadosPlanilha = this.tabela[index];
      const { historico } = dadosPlanilha;

      promessas.push(limit(() => requisicaoHistorico(historico, this.codigoEmpresa, this.codigoContaContabil)));
    }

    const resultadoPromesa = await Promise.all(promessas);

    for (let i = 0; i < analisesHistorico.length; i++) {
      const index = analisesHistorico[i];
      const dadosPlanilha = this.tabela[index];
      const historicoEncontrado = resultadoPromesa[i];

      if (historicoEncontrado) {
        dadosPlanilha.baixasEncontradas = historicoEncontrado;
        this.analises = this.analises.filter((analise) => analise !== index);
      }
    }
  }

  async agrupaBaixas() {
    for (let i = 0; i < this.analises.length; i++) {
      const index = this.analises[i];
      const dadosPlanilha = this.tabela[index];
      const { documento, entrada, saida } = dadosPlanilha;
      const baixasRelevantes = this.buscaBaixas(entrada, saida, documento);
      dadosPlanilha.baixasEncontradas = baixasRelevantes;
    }
  }


  confereBaixasEncontradas() {
    const { padraoVencimento } = this.configuracoes;
    const tabela = this.tabela;
    const parcelasUsadas = new Set();


    if (padraoVencimento) {
      const linhasAnalisar = tabela.filter((__, idx) => this.analises.includes(idx));
      for (const [idx, dadosPlanilha] of linhasAnalisar.entries()) {
        if (DEBUG) console.log("dadosPlanilha ", dadosPlanilha)
        const { entrada, saida, documento } = dadosPlanilha;
        const baixasValidas = documento ? dadosPlanilha.baixasEncontradas : Number(entrada) ? this.todasBaixasEmAbertoSaidaServico : this.todasBaixasEmAbertoEntrada;
        const baixaCasoPadrao = baixasValidas?.[0];
        let baixasEncontradas = baixasValidas?.filter((e) => e.vltotal > 0 && !parcelasUsadas.has(`${e.documento}-${e.parcela}`));
        const dataPagamento = dadosPlanilha.data;
        baixasEncontradas = ordenaPorProximidadeVencimentoEValor(baixasEncontradas, dataPagamento, entrada || saida);
        if (DEBUG) console.log(`baixas listadas para linha ${idx} `, baixasEncontradas);

        if (baixasEncontradas && baixasEncontradas.length > 0) {
          if (DEBUG) console.log(`1 baixa para a linha ${idx}`);
          dadosPlanilha.selecionaBaixa(baixasEncontradas[0]);
          parcelasUsadas.add(`${baixasEncontradas[0].documento}-${baixasEncontradas[0].parcela}`)

        } else if (baixaCasoPadrao) {
          if (DEBUG) console.log(`caso padrão para a linha ${idx}`);
          dadosPlanilha.selecionaBaixa(baixaCasoPadrao);
          parcelasUsadas.add(`${baixaCasoPadrao.documento}-${baixaCasoPadrao.parcela}`)
        }
      }
    }

    for (let index = 0; index < tabela.length; index++) {
      const dadosPlanilha = tabela[index];
      dadosPlanilha.conferebaixasEncontradas(this.configuracoes);
    }

    this.analises = [];
  }

  confereModificacoesPlanilha(baixasAnterior) {
    if (baixasAnterior.configuracoes !== this.configuracoes || !baixasAnterior || this.codigoEmpresa !== baixasAnterior.codigoEmpresa) {
      this.planilhaExcel.forEach((linha, index) => {
        let dadosPlanilha = new DadosPlanilha(this.configuracoes);
        dadosPlanilha.transformaDadosPlanilhaEmObjeto(linha);
        this.tabela.push(dadosPlanilha);
        this.analises.push(index);
      });

      return;
    }

    const tabelaAntiga = baixasAnterior.tabela;

    const tabelaAtual = this.tabela;
    const planilhaAtual = this.planilhaExcel;
    const tamanhoPlanilhaAtual = planilhaAtual.length;

    for (let index = 0; index < tamanhoPlanilhaAtual; index++) {
      let linhaAtual = new DadosPlanilha(this.configuracoes);
      let linhaMantida;
      linhaAtual.transformaDadosPlanilhaEmObjeto(planilhaAtual[index]);
      let tudoIgual = false;

      tabelaAntiga.forEach((linhaAnalisada) => {
        if (
          linhaAtual.data === linhaAnalisada.data &&
          linhaAtual.historico === linhaAnalisada.historico &&
          linhaAtual.documento === linhaAnalisada.documento &&
          linhaAtual.entrada === linhaAnalisada.entrada &&
          linhaAtual.saida === linhaAnalisada.saida &&
          linhaAtual.desconto === linhaAnalisada.desconto &&
          linhaAtual.juros === linhaAnalisada.juros
        ) {
          tudoIgual = true;
          linhaMantida = linhaAnalisada;
        }
      });

      if (tudoIgual) {
        tabelaAtual.push(linhaMantida);
      } else {
        tabelaAtual.push(linhaAtual);
        this.analises.push(index);
      }
    }
  }

  recuperaCache() {
    const tabelaCache = this.tabela;
    const novaTabela = tabelaCache.map((dadosPlanilhaCache) => {
      let dadosPlanilhaRecuperado = new DadosPlanilha(this.configuracoes);

      dadosPlanilhaRecuperado = Object.assign(dadosPlanilhaRecuperado, dadosPlanilhaCache);
      dadosPlanilhaRecuperado.recuperaCache();

      return dadosPlanilhaRecuperado;
    });

    this.tabela = novaTabela;
  }
}

export function ordenaPorProximidadeVencimentoEValor(baixasList, dataPagamento, valorPago) {
  if (DEBUG) console.log(`Sorting input: ${baixasList.length} baixas, date: ${dataPagamento}, val: ${valorPago}`);
  if (!baixasList || !baixasList.length || !dataPagamento) return baixasList;

  const formattedDate = dataPagamento.match(/^\d{2}\/\d{2}\/\d{4}$/) ? dataPagamento.split("/").reverse().join("-") : dataPagamento;
  const formattedValue = Number(valorPago);
  const baseDate = dayjs(formattedDate);

  baixasList.sort(function (a, b) {
    var dateDifferenceA = Math.abs(dayjs(a.vencimento).diff(baseDate, "days"));
    var dateDifferenceB = Math.abs(dayjs(b.vencimento).diff(baseDate, "days"));
    var valueDifferenceA = (a.vltotal && valorPago) ? Math.abs(a.vltotal - formattedValue) : 1;
    var valueDifferenceB = (b.vltotal && valorPago) ? Math.abs(b.vltotal - formattedValue) : 1;
    var differenceA = dateDifferenceA + valueDifferenceA;
    var differenceB = dateDifferenceB + valueDifferenceB;
    return differenceA - differenceB;
  });

  if (DEBUG) console.log(`Sorting output: index 0 val: ${baixasList[0]?.vltotal} e due: ${baixasList[0].vencimento}`)
  return baixasList;
}
