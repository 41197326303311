import React, { useState } from "react";
import Input from "../../components/UI/Input/Input";

const useInput = (
  label,
  placeholder,
  name,
  defaultValue,
  inputtype,
  inputFunc
) => {
  const [value, setValue] = useState(defaultValue);

  const input = (
    <Input
      label={label}
      placeholder={placeholder}
      name={name}
      valor={value}
      inputtype={inputtype}
      onChangeFunc={(e) => {
        try {
          if (inputtype === "data") {
            setValue(e);
          } else {
            setValue(e.target.value);
          }

          if (inputFunc) inputFunc(e);
        } catch (error) {
          console.log(error);
        }
      }}
    />
  );
  return [value, input];
};

export default useInput;
