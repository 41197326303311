import BaixaSelecionada from "../BaixaSelecionada/BaixaSelecionada";

export default class DadosPlanilha {
  constructor(configuracoes) {
    this.id = "";
    this.data = "";
    this.historico = "";
    this.documento = 0;
    this.entrada = 0;
    this.saida = 0;
    this.juros = 0;
    this.desconto = 0;
    this.baixasEncontradas = null;
    this.baixaSelecionada = null;
    this.encontrouApenasPeloDocumento = false;
    this.configuracoes = configuracoes;
  }

  conferebaixasEncontradas() {
    const resultados = this.baixasEncontradas;

    if (this.baixaSelecionada || !resultados) return;

    if (resultados.length === 1) {
      this.selecionaBaixa(resultados[0]);
      return;
    }
  }

  selecionaBaixa(resultadoSelecionado) {
    try {
      const valorDoPagamento =
        (this.entrada || this.saida) - this.juros + this.desconto;
      const valorDaNota = resultadoSelecionado.vlconsiderado;

      const baixaSelecionada = new BaixaSelecionada(
        this.configuracoes,
        valorDoPagamento,
        valorDaNota
      );

      baixaSelecionada.espalhaProps(resultadoSelecionado);
      baixaSelecionada.defineRotaParaBaixa();
      this.configuraBaixaSelecionada(baixaSelecionada);

      this.baixaSelecionada = baixaSelecionada;
    } catch (error) {
      console.log(error);
    }
  }

  configuraBaixaSelecionada(baixaSelecionada) {
    const { valorDaNota, rota } = baixaSelecionada;
    baixaSelecionada.tickColor = "green";
    baixaSelecionada.tickIcon = "faCheck";

    if (this.encontrouApenasPeloDocumento)
      baixaSelecionada.tickIcon = "faExclamation";

    if (rota === "comJuros") {
      baixaSelecionada.juros = this.juros
        ? this.juros
        : (this.entrada || this.saida) - valorDaNota;
      baixaSelecionada.desconto = "";
      baixaSelecionada.tickColor = "red";
    }

    if (rota === "comDesconto") {
      baixaSelecionada.desconto = this.desconto
        ? this.desconto
        : valorDaNota - (this.entrada || this.saida);
      baixaSelecionada.juros = "";
      baixaSelecionada.tickColor = "yellow";
    }

    if (rota === "comBaixaParcial") {
      baixaSelecionada.desconto = "";
      baixaSelecionada.juros = this.juros;
      baixaSelecionada.tickColor = "black";
    }
  }

  carregando() {
    this.baixasEncontradas = null;
  }

  transformaDadosPlanilhaEmObjeto(linha) {
    this.data = linha[0] || "";
    this.historico = linha[1] || "";
    this.documento = parseInt(linha[2]) || "";
    //----------------------------------------------------Alterado Laís
    this.entrada =
      parseFloat(linha[3].toString().replace(",", ".")) ||
        parseFloat(linha[3].toString().replace(",", ".")) === 0
        ? parseFloat(linha[3].toString().replace(",", "."))
        : "";
    this.saida =
      parseFloat(linha[4].toString().replace(",", ".")) ||
        parseFloat(linha[4].toString().replace(",", ".")) === 0
        ? parseFloat(linha[4].toString().replace(",", "."))
        : "";
    this.desconto =
      parseFloat(linha[5].toString().replace(",", ".")) ||
        parseFloat(linha[5].toString().replace(",", ".")) === 0
        ? parseFloat(linha[5].toString().replace(",", "."))
        : "";
    this.juros =
      parseFloat(linha[6].toString().replace(",", ".")) ||
        parseFloat(linha[6].toString().replace(",", ".")) === 0
        ? parseFloat(linha[6].toString().replace(",", "."))
        : "";
    //----------------------------------------------------Alterado Laís
  }

  recuperaCache() {
    this.selecionaBaixa(this.baixaSelecionada);
  }
}
