import React, { useState, useEffect } from "react";
import CheckBox from "../../components/UI/Checkbox/Checkbox";

const useInput = (label, defaultValue = false) => {

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue]);

  let onChange = (e) => setValue(!value);

  const input = <CheckBox label={label} clicked={onChange} checked={value} />;
  return [value, input, setValue];
};

export default useInput;
