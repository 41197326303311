import React, { createContext, useReducer } from "react";
import { reducer } from "./reducer";
import {
  setDespacho,
  setFiltroHandler,
  removeItem,
  adicionaItem,
  analisaItem,
} from "./actionTypes";

//Initial State
const initialState = {
  listaEmpresas: [],
  listaContas: [],
  listaHistoricos: [],
  excelDados: "",
  excelTotalizador: "",
  excelDadosBaixas: "",
  empresaSelecionada: "",
  contaContabilSelecionada: "",
  tabelaDados: "",
  baixas: "",
  promessaSelecionada: "",
  configuracoes: { padraoVencimento: false, padraoBaixaParcial: false },
  opcaoSelecionada: "",
  opcaoSelecionadaBanco: "",
  modalOpcao: false,
  checkboxSim: false,
  checkboxNao: false,
  percentage: 0,
};

//Create context
export const IntegradorContext = createContext(initialState);

//Create provider
const IntegradorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  //Despachantes
  const despachante = (despacho) => {
    dispatch({ type: setDespacho, despacho });
  };

  const filtroHandler = (filtro) => {
    dispatch({ type: setFiltroHandler, filtro });
  };

  const removeItemTabela = (indexItem) => {
    dispatch({ type: removeItem, qualItem: indexItem });
  };

  const adicionaItensTabela = (itens, posicao) => {
    dispatch({
      type: adicionaItem,
      itensParaAdicionar: itens,
      qualPosicao: posicao,
    });
  };

  const analisaItensTabela = (itens) => {
    dispatch({
      type: analisaItem,
      itensParaAnalisar: itens,
    });
  };

  const despachaBaixaSelecionada = (
    baixaSelecionada,
    index,
    padraoBaixaParcial
  ) => {
    dispatch({
      type: "DESPACHA_BAIXA_SELECIONADA",
      baixaSelecionada,
      index,
      padraoBaixaParcial,
    });
  };

  return (
    <IntegradorContext.Provider
      value={{
        ...state,
        despachante,
        filtroHandler,
        removeItemTabela,
        adicionaItensTabela,
        analisaItensTabela,
        despachaBaixaSelecionada,
      }}
    >
      {children}
    </IntegradorContext.Provider>
  );
};

//Export hof Provider

export const comProvider = (Integrador) => {
  class IntegradorComProvedorAcoplado extends React.Component {
    render() {
      return (
        <IntegradorProvider>
          <Integrador />
        </IntegradorProvider>
      );
    }
  }
  return IntegradorComProvedorAcoplado;
};
