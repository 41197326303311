import React, { useContext, useState } from "react";
import { IntegradorContext } from "../../../util/context/context";
import Input from "../../../../../components/UI/Input/Input";
import Button from "../../../../../components/UI/Button/Button";
import DadosPlanilha from "../util/constructors/DadosPlanilha/DadosPlanilha";
import { geraTabela } from "../util/controller";

const Separador = ({ qualPromessa, closeSeparador, excelDados }) => {
  const {
    baixas,
    adicionaItensTabela,
    despachante,
    analisaItensTabela,
    configuracoes,
    setSeparador,
    setLinhaSelecionada,
  } = useContext(IntegradorContext);
  const { entrada, saida, data, historico, desconto, juros, documento } =
    baixas.tabela[qualPromessa];
  const [counter, setCounter] = useState(entrada || saida);
  const [valores, setValores] = useState([]);

  //Function
  function useInput({ label, placeholder, name, defaultValue, type, step }) {
    const [value, setValue] = useState(defaultValue);
    const input = (
      <Input
        label={label}
        placeholder={placeholder}
        name={name}
        valor={value}
        onChange={(e) => setValue(e.target.value)}
        type={type}
        step={step}
      />
    );
    return [value, input];
  }

  const [valor, valorInput] = useInput({
    label: "Valor",
    placeholder: "",
    name: "valor",
    defaultValue: "",
    type: "number",
    step: "",
  });

  return (
    <div className="separadorContainer">
      <table className="separadorTable">
        <thead>
          <tr>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          {valores.map((valor, index) => {
            return (
              <tr key={index}>
                <td>R$ {valor}</td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th>Total: R$ {counter}</th>
          </tr>
        </tfoot>
      </table>

      {valorInput}

      <div className="separador_buttons_container">
        <Button
          clicked={() => {
            if (counter - valor >= 0) {
              const arrayValores = valores;
              arrayValores.push(valor);
              setValores(arrayValores);
              setCounter(counter - valor);
            } else {
              alert("num podi");
            }
          }}
        >
          Lançar
        </Button>
        <Button
          clicked={async () => {
            if (counter === 0) {
              let analises = [];

              let novosItens = valores.map((valor, index) => {
                if (index > 0) {
                  excelDados.insertRow(1, qualPromessa + (index - 1));
                }
                excelDados.setRowData(qualPromessa + index, [
                  data,
                  historico,
                  documento,
                  entrada ? valor : "",
                  saida ? valor : "",
                  desconto,
                  juros,
                ]);

                analises.push(qualPromessa + index);

                const novoItem = new DadosPlanilha(configuracoes);
                novoItem.transformaDadosPlanilhaEmObjeto([
                  data,
                  historico,
                  documento,
                  entrada ? valor : "",
                  saida ? valor : "",
                  desconto,
                  juros,
                ]);
                novoItem.tickColor = "red";
                novoItem.tickIcon = "faTimes";
                novoItem.baixa_selecionada = false;
                return novoItem;
              });

              try {
                await adicionaItensTabela(novosItens, qualPromessa);

                await analisaItensTabela(analises);

                await baixas.fazRequisicoesEntrada();
                await baixas.fazRequisicoesSaidaServico();
              } catch (error) {
                console.log(error);
              }
              baixas.confereBaixasEncontradas();

              despachante({
                tabelaDados: geraTabela(
                  baixas,
                  setSeparador,
                  setLinhaSelecionada
                ),
              });
              baixas.planilhaExcel = excelDados.getData();
              localStorage.setItem("Integrador", JSON.stringify(baixas));
              closeSeparador();
            } else {
              alert("num podi");
            }
          }}
        >
          Separar
        </Button>
      </div>
    </div>
  );
};

export default Separador;
