import "./sass/main.scss";
import React from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./darkMode/globalStyles";
import { darkTheme } from "./darkMode/themes";
import { toast } from "react-toastify";
import MenuIntegrador from "./containers/Integrador/Integrador";
import { SignedIn, SignedOut, SignInButton, UserButton } from "@clerk/clerk-react";

toast.configure();
const App = () => {
  return (
    <>
      <SignedOut>
        <SignInButton>
          <button className="btn btn-primary">Entrar</button>
        </SignInButton>
      </SignedOut>
      <SignedIn>
        <ThemeProvider darkMode={true} theme={darkTheme}>
          <GlobalStyles />
          <div className="containerViewport">
            <div className="meioPrincipal">
              <MenuIntegrador />
            </div>
          </div>
        </ThemeProvider>
      </SignedIn>
    </>
  );
};
export default App;
