import React, { useState, useContext } from "react";
import { AnaliseContext } from "../../../util/context";
import Button from "../../../../../../../../components/UI/Button/Button";
import Select from "../../../../../../../../components/UI/Select/Select";
import useInput from "../../../../../../../../helpers/customHooks/useInput";
import { IntegradorContext } from "../../../../../../util/context/context";

const TemplateResultadoContabil2 = ({
  baixasSelecionadas,
  counter,
  setBaixasSelecionadas,
  setCounter,
}) => {
  //Context
  const { qualPromessa } = useContext(AnaliseContext);
  const { baixas, listaContas } = useContext(IntegradorContext);

  //Variáveis
  const [contraPartida, setContraPartida] = useState("");
  const linhaAnalisada = baixas.tabela[qualPromessa];
  const { historico } = linhaAnalisada;

  const [codigoDoHistorico, codigoDoHistoricoInput] = useInput(
    "Código do Histórico",
    "",
    "codigoDoHistorico",
    "",
    "text",
    ""
  );
  const [historicoValue, historicoInput] = useInput(
    "Histórico",
    "",
    "historico",
    historico,
    "text"
  );

  const [valorValue, valorInput] = useInput("Valor", "", "valor", 0, "number");

  let template = (
    <>
      <div className="lancamentoContabilContainer">
        <Select
          options={listaContas}
          title="Conta Contábil"
          placeholder={"Selecione a conta!"}
          onChange={(valorSelecionado) => setContraPartida(valorSelecionado)}
          value={contraPartida}
        />
        {codigoDoHistoricoInput}
        {historicoInput}
        {valorInput}

        <Button
          classe="lancar-btn"
          clicked={() => {
            let baixas = [...baixasSelecionadas];
            baixas.push({
              contraPartida,
              codigoDoHistorico,
              historicoValue,
              baixa_selecionada: true,
              tickColor: "green",
              tickIcon: "faCheck",
              operacao: 1,
              valor: valorValue,
              vlconsiderado: valorValue,
              documento: "Lançamento Contábil",
              dtvencimento: "-",
              parcela: "-",
            });

            let novoCounter = transformaEmDuasCasasDecimais(
              counter - parseFloat(valorValue)
            );

            if (novoCounter < 0) {
              alert("Num podi");
            } else {
              setBaixasSelecionadas(baixas);
              setCounter(novoCounter);
            }
          }}
        >
          Lançar
        </Button>
      </div>
    </>
  );

  return template;
};

export default TemplateResultadoContabil2;

const transformaEmDuasCasasDecimais = (num) => {
  let valorDuasCasas = Math.round(parseFloat(num) * 100) / 100;

  if (isNaN(valorDuasCasas.toString())) {
    return "";
  }

  return valorDuasCasas.toString();
};
