import React from "react";
import RowsColunas from "./RowsColunas/RowsColunas";
import RowsTabela from "./RowsTabela/RowsTabela";

const Table = ({ rowsFixo, search }) => {
  return (
    <>
      <table className="tabelaReact__tabelaComponente">
        <RowsColunas />
        <RowsTabela rowsFixo={rowsFixo} />
      </table>
    </>
  );
};

export default Table;
