import React from "react";
import moment from "moment";

export const geraAtributos = (valores, atributos) => {
  let reactArray = [];
  for (let index = 0; index < atributos.length; index++) {
    const atributo = atributos[index];
    const valor = valores[index];

    reactArray.push(
      <div
        className="analiseNotasContainer__info--atributo"
        key={`${index}${valor}`}
      >
        <p>
          <b>{atributo}</b>
          <span>{valor}</span>
        </p>
      </div>
    );
  }

  return reactArray;
};

//Formatador
const formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export const { format } = formatter;

export const vencimentoFormatado = (data) =>
  moment(moment(data).format("DD/MM/YYYY"))._i;

export const confrontoPlanilhaNota = (valorPlanilha, valorDaNota) => {
  let valorDoPagamento = parseFloat(valorPlanilha);

  if (valorDoPagamento > valorDaNota) {
    return {
      classe: "analiseNotasContainer__headBox--red",
      aviso: "Valor maior do que a nota. A diferença será lançada como juros.",
      tipo: 0,
    };
  }

  if (valorDoPagamento < valorDaNota) {
    return {
      classe: "analiseNotasContainer__headBox--yellow",
      aviso: "Valor menor do que a nota. Escolha o lançamento:",
      tipo: 1,
    };
  }

  return {
    classe: "analiseNotasContainer__headBox--green",
    aviso: "",
    tipo: 2,
  };
};