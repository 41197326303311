import React from "react";
import PropTypes from "prop-types";
import DateTimePicker from "react-datetime-picker";

/**
 * @param props {
 * @param inputtype: entre['',], altera tipo do input
 * @param label: Titulo do Input
 * @param placeholder: Titulo do PlaceHolder
 * @param name: Nome do input
 * @param onChangeFunc: Função que é ativada depois de uma mudança feita no input
 * @param largura: width do input
 *
 * }
 */

const Input = ({
  inputtype = "",
  label = "",
  placeholder = "",
  name,
  onChangeFunc = () => {},
  id,
  valor,
  onFocusOut,
  ...rest
}) => {
  let input = null;

  const obterLabel = (text) => {
    if (text)
      return (
        <div className="input_container__label">
          <label htmlFor={name}>{`${text}:`}</label>
        </div>
      );
    return "";
  };
  switch (inputtype) {
    case "textarea":
      input = (
        <div className="input_container">
          {obterLabel(label)}
          <div className="input_container__wrapper">
            <textarea
              className="input_container__wrapper--textarea input_container__wrapper--text__global"
              placeholder={placeholder}
              name={name}
              onChange={onChangeFunc}
              on={id}
              type={inputtype}
              {...rest}
            >
              {valor}
            </textarea>
          </div>
        </div>
      );
      break;
    case "data":
      input = (
        <div className="input_container">
          {obterLabel(label)}
          <div className="input_container__wrapper">
            <DateTimePicker
              onChange={onChangeFunc}
              value={valor}
              format="d/M/y"
              style={{ width: "100%" }}
              calendarIcon={null}
              calendarType="US"
              {...rest}
            />
          </div>
        </div>
      );
      break;
    default:
      input = (
        <div className={label ? "input_container" : ""}>
          {obterLabel(label)}

          <div className="input_container__wrapper">
            <input
              className="input_container__wrapper--input input_container__wrapper--input__global"
              placeholder={placeholder}
              name={name ? name : ""}
              onChange={onChangeFunc}
              onBlur={onFocusOut}
              on={id}
              value={valor ? valor : ""}
              type={inputtype}
              {...rest}
            />
          </div>
        </div>
      );
  }

  return input;
};

Input.propTypes = {
  inputtype: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChangeFunc: PropTypes.func,
};

export default Input;
