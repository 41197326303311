import React from "react";
import {
  salvaHistorico,
  salvaOpcao,
  salvaOperacao,
  salvaParteHistorico,
} from "../../../../../helpers/requisicoesHttp/integradorRequisicoes";
import useSelect from "../../../../../helpers/customHooks/useSelect";
import useInput from "../../../../../helpers/customHooks/useInput";
import useCheckBox from "../../../../../helpers/customHooks/useCheckBox";
import { notify } from "../../../../../components/UI/Notificacao/Notificacao";
import Opcao from "../util/constructors/Opcao";

const NovaOpcao = ({
  listaContas,
  empresaSelecionada,
  contaContabilSelecionada,
}) => {
  const [operacaoValue, operacaoSelect] = useSelect(
    "Operações",
    [{ value: 0, label: "Lançamento Contábil" }],
    "Selecione a Operação",
    false,
    true
  );

  const [ctPartidaValue, ctPartidaSelect] = useSelect(
    "ContraPartida",
    listaContas,
    "Selecione a Contra Partida",
    false,
    true
  );

  const [codigoValue, codigoInput] = useInput(
    "Código",
    "Qual código?",
    "codigo",
    null,
    "text"
  );

  const [parteHistoricoValue, parteHistoricoInput] = useInput(
    "ParteHistórico",
    "Qual parte histórico?",
    "parteHistorico",
    null,
    "text"
  );

  const [historicoValue, historicoInput] = useInput(
    "Histórico",
    "Qual histórico?",
    "historico",
    null,
    "text"
  );

  const [shValue, shInput] = useCheckBox("Substituir Histórico", false);
  const [bpValue, bpInput] = useCheckBox("Baixa Parcial?", false);

  const adicionarNovaOpcao = async () => {
    if (
      operacaoValue.value === 0 &&
      (!ctPartidaValue ||
        !codigoValue ||
        !parteHistoricoValue ||
        !historicoValue)
    ) {
      notify("Por favor preencher todos os campos!");
      return;
    }
    if (operacaoValue.value === 0) {
      const idHistorico = await salvaHistorico(historicoValue);
      const idOperacao = 1; //TODO: while we fix the error with the API at "salvaOperacao(operacaoValue.label)", as this doesn't seem to be very important for the app working
      const idParteHistorico = await salvaParteHistorico(parteHistoricoValue);

      const novaOpcoes = new Opcao(
        idParteHistorico,
        idOperacao,
        idHistorico,
        ctPartidaValue.value,
        parseInt(codigoValue),
        empresaSelecionada,
        contaContabilSelecionada,
        shValue ? 1 : 0,
        0
      );

      await salvaOpcao(novaOpcoes);
      return;
    }

    if (operacaoValue.value !== 0 && !parteHistoricoValue) {
      notify("Por favor preencher todos os campos!");
      return;
    }

    const idOperacao = await salvaOperacao(operacaoValue.label);
    const idParteHistorico = await salvaParteHistorico(parteHistoricoValue);

    const novaOpcoes = new Opcao(
      idParteHistorico,
      idOperacao,
      2780,
      undefined,
      undefined,
      empresaSelecionada,
      contaContabilSelecionada,
      0,
      bpValue ? 1 : 0
    );

    await salvaOpcao(novaOpcoes);
  };

  let template = null;

  if (operacaoValue && operacaoValue.value === 0) {
    template = [
      ctPartidaSelect,
      codigoInput,
      parteHistoricoInput,
      historicoInput,
      shInput,
    ];
  } else if (operacaoValue && operacaoValue.value !== 0) {
    template = [parteHistoricoInput, bpInput];
  }

  return (
    <>
      <button
        className="botaoReact botaoReact__acao"
        onClick={() => adicionarNovaOpcao()}
      >
        Adicionar
      </button>
      {operacaoSelect}
      {template}
    </>
  );
};

export default NovaOpcao;
