import {
  setDespacho,
  setFiltroHandler,
  removeItem,
  adicionaItem,
  analisaItem,
} from "./actionTypes";

export function reducer(state, action) {
  switch (action.type) {
    //Atualiza filtros
    case setDespacho:
      return updateState(state, action);

    case setFiltroHandler:
      return updateFiltro(state, action);

    case removeItem:
      return removeItemTabela(state, action);

    case adicionaItem:
      return adicionaItensTabela(state, action);

    case analisaItem:
      return analisaItensTabela(state, action);

    case "DESPACHA_BAIXA_SELECIONADA":
      return selecionaBaixa(state, action);

    default:
      throw new Error(`Ação inválida!  -  ${action.type}`);
  }
}

const selecionaBaixa = (state, action) => {
  const { index, baixaSelecionada, padraoBaixaParcial } = action;
  const dadosPlanilha = state.baixas.tabela[index];
  dadosPlanilha.configuracoes.padraoBaixaParcial = padraoBaixaParcial;
  dadosPlanilha.selecionaBaixa(baixaSelecionada);

  return {
    ...state,
  };
};

const updateFiltro = (state, action) => {
  const { filtro } = action;
  let filtroFormatado;

  for (const key in filtro) {
    const elements = filtro[key];
    if (elements)
      filtroFormatado = elements.map((element) => {
        let value = element.codi_emp || element.codi_cta || element.id;

        let label = `${element.codi_emp || element.codi_cta || ``} - ${
          element.nome_emp || element.nome_cta || element.historico
        }`;

        if (element.historico) {
          label = element.historico;
        }

        return { value, label };
      });

    return {
      ...state,
      [key]: filtroFormatado,
    };
  }
};

const updateState = (state, action) => {
  return {
    ...state,
    ...action.despacho,
  };
};

const removeItemTabela = (state, action) => {
  let baixas = state.baixas;
  baixas.tabela.splice(action.qualItem, 1);

  return {
    ...state,
    baixas,
  };
};

const adicionaItensTabela = (state, action) => {
  let baixas = state.baixas;

  baixas.tabela.splice(action.qualPosicao, 1);
  action.itensParaAdicionar.forEach((item) => {
    baixas.tabela.splice(action.qualPosicao, 0, item);
  });

  return {
    ...state,
  };
};

const analisaItensTabela = (state, action) => {
  let baixas = state.baixas;
  const { itensParaAnalisar } = action;
  baixas.analises = itensParaAnalisar;

  return {
    ...state,
  };
};
