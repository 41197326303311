import React, { useContext } from "react";

/*import {
  compararStrings,
  compararNumeros,
  checarSeEhStringNumerica,
  checarData,
  compararDatas,
} from "../../utils/controller";*/

import { TabelaContext } from "../../../utils/context";

const RowsColunas = () => {
  const { colunas } = useContext(TabelaContext);

  const geraListaColunas = () => {
    const colunasTemplate = colunas.map((coluna, index) => {
      return <th key={index}>{coluna}</th>;
    });

    return (
      <thead className="tabelaReact__tabelaComponente__colunaComponente">
        <tr>{colunasTemplate}</tr>
      </thead>
    );
  };

  return <>{geraListaColunas()}</>;
};

export default RowsColunas;
