import React from "react";

import { element, arrayOf, shape, func, oneOfType, string } from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

/**
 * @param props {
 * @param titulosDasTabs: array com objetos explicitando o nome e SE existe restrição ou não
 * @param children: array com elements de react
 */

const CardWithTabs = ({ titulosDasTabs, children }) => {
  let titulos = titulosDasTabs.map((titulo, index) => {
    if (!titulo.restricao && !titulo.onClickFunc) {
      return <Tab key={index}>{titulo.nome}</Tab>;
    } else if (titulo.restricao) {
      return (
        <Tab key={index} onClick={titulo.restricao} disabled>
          {titulo.nome}
        </Tab>
      );
    } else if (titulo.onClickFunc) {
      return (
        <Tab key={index} onClick={titulo.onClickFunc}>
          {titulo.nome}
        </Tab>
      );
    }
    return '';
  });

  let paineis = children.map((child, index) => {
    return <TabPanel key={index}>{child}</TabPanel>;
  });

  return (
    <Tabs>
      <TabList>{titulos}</TabList>

      {paineis}
    </Tabs>
  );
};

CardWithTabs.propTypes = {
  titulosDasTabs: arrayOf(shape({ /*nome: string,*/ restricao: oneOfType([func, string]), onClickFunc: func })),
  children: arrayOf(element),
};

export default CardWithTabs;
