export function geraDocumento(espelho, conta) {
  let txt = "7001||0|0|0|E| \n";
  let numeroAleatorio =
    Math.floor(Math.random() * 10) + 1 * Math.floor(Math.random() * 100) + 1 * Math.floor(Math.random() * 1000) + 1 * Math.floor(Math.random() * 10000) + 1 * Math.floor(Math.random() * 10000) + 1;
  let competencia = espelho[0][0];
  competencia = competencia.split("/");
  txt += `7000|${numeroAleatorio}|01/${competencia[1]}/${competencia[2]}|${conta}|0|N| \n`;

  espelho.forEach((linha) => {
    //Fixo 7100
    txt += `7001|${linha[0]}|${linha[1]}|${linha[2]}|${linha[3] || linha[4]}|${linha[3] ? "E" : "S"}|\n`;
  });

  return txt;
}
