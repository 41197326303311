import React from "react";
import { TabelaProvider } from "./utils/context";
import SearchBox from "./children/SearchBox/SearchBox";
import Table from "./children/Table/Table";
import Pagination from "./children/Pagination/Pagination";
import Indicador from "./children/Indicador/Indicador";

function TabelaReact({
  colunas,
  rowsFixo,
  onClickRow = () => {},
  icones,
  search = "",
  destrava = false,
  despachante = () => {},
  searchFilter = true,
  paginationFilter = true,
}) {
  // Render the UI for your table
  return (
    rowsFixo.length > 0 && (
      <TabelaProvider
        colunas={colunas}
        rows={rowsFixo}
        icones={icones}
        onClickRow={onClickRow}
        search={search}
        destrava={destrava}
        despachantePai={despachante}
        paginationFilter={paginationFilter}
        searchFilter={searchFilter}
      >
        <div className="tabelaReact__Container">
          <div className="tabelaReact__searchSelectContainer">
            <SearchBox rowsPai={rowsFixo} />
          </div>
          <Table rowsFixo={rowsFixo} />
          <div className="tabelaReact__paginacaoContainer">
            <Indicador />
            <Pagination />
          </div>
        </div>
      </TabelaProvider>
    )
  );
}

export default TabelaReact;
