import React, { useEffect, useState, useMemo, useContext } from "react";
import { Html5Table } from "window-table";
import iconv from "iconv-lite";
import { geraTabela, geraDocumento, preparaDocumento } from "./util/controller";
import Modal from "../../../../components/UI/Modal/Modal";
import Button from "../../../../components/UI/Button/Button";
import { IntegradorContext } from "../../util/context/context";
import { AnaliseProvider } from "./modal/util/context";
import Analise from "./modal/Analise";
import Separador from "./separador/Separador";
import Baixas from "./util/constructors/Baixas/Baixas";
import useStorage from "../../../../helpers/customHooks/useStorage";

const Integracao = () => {
  //Context
  const { contaContabilSelecionada, baixas, excelDados, empresaSelecionada, tabelaDados, despachante, configuracoes } = useContext(IntegradorContext);

  const [valorRecuperado, salvarValor] = useStorage("Integrador");

  //Estado
  const [modal, setModal] = useState(false);
  const [separador, setSeparador] = useState(false);
  const [linhaSelecionada, setLinhaSelecionada] = useState("");
  const [percentage, setPercentage] = useState(0);

  //Functions
  const toggleModal = () => {
    setModal(!modal);
  };

  const closeSeparador = () => {
    setSeparador(false);
  };

  //UseEffect
  useEffect(() => {
    let baixasAnalisadas = new Baixas(empresaSelecionada, contaContabilSelecionada, configuracoes, excelDados.getData());
    despachante({ setSeparador, setLinhaSelecionada });

    let baixasCache = baixas;
    if (!baixas && valorRecuperado()) {
      baixasCache = transformaJSON(valorRecuperado(), excelDados, configuracoes);
    }

    baixasAnalisadas.confereModificacoesPlanilha(baixasCache);

    (async function fetch() {
      //Carregando..
      despachante({
        tabelaDados: geraTabela(baixasAnalisadas),
      });

      //faz as Requisições
      //requisição geral
      await baixasAnalisadas.fazRequisicaoTodasBaixasEmAberto();
      await baixasAnalisadas.fazRequisicoesHistorico();
      //faz o match das baixas por lançamento
      await baixasAnalisadas.agrupaBaixas()
      //confere as obrigaçoes encontradas
      baixasAnalisadas.confereBaixasEncontradas();

      //Carregado!
      despachante({
        tabelaDados: geraTabela(baixasAnalisadas, setSeparador, setLinhaSelecionada),
        baixas: baixasAnalisadas,
      });

      salvarValor(JSON.stringify(baixasAnalisadas));
    })();
  }, [empresaSelecionada]);

  const columns = [
    { key: "status", width: 100, title: "Status" },
    { key: "conta", width: 100, title: "Conta" },
    { key: "data", width: 100, title: "Data" },
    { key: "documento", width: 100, title: "Documento" },
    { key: "historico", width: 100, title: "Histórico" },
    { key: "valor", width: 100, title: "Valor" },
    { key: "separador", width: 50, title: "" },
  ];

  const CustomRow = (props) => {
    // Here we are using the `Tr` component from stylestrap
    // ...which allows us to pass in a css attribute
    return (
      <tr
        {...props} // It is important that we forward the original props
        styles={{
          // It is important that we merge the original styles
          // See the components API for more details
          ...props.styles,
          cursor: "pointer",
        }}
        onClick={(e) => {
          let rowNumber = e.currentTarget.getAttribute("index");

          setLinhaSelecionada(rowNumber);

          toggleModal();
        }}
      />
    );
  };

  return (
    <>
      <div className="integracao_container">
        {useMemo(
          () => (
            <>
              <Html5Table Row={CustomRow} data={tabelaDados} columns={columns} rowClassName={(index) => (index % 2 === 0 ? "even-row" : "odd-row")} />
            </>
          ),
          [tabelaDados]
        )}
      </div>

      <Button
        clicked={async () => {
          let documento = preparaDocumento(baixas);

          try {
            documento = await geraDocumento(documento, setPercentage);
            handleDownload(documento);
          } catch (error) {
            console.log(error);
          }
        }}
        classe="botao_gerador_integrador"
      >
        Gerar
      </Button>

      <Modal title="Análise" closeModal={toggleModal} isModalOpen={modal} width="100%">
        <AnaliseProvider>
          <Analise qualPromessa={linhaSelecionada} closeModal={toggleModal} />
        </AnaliseProvider>
      </Modal>
      <Modal title="Separador" closeModal={closeSeparador} isModalOpen={separador} width="35%" min_width="35%">
        <Separador qualPromessa={linhaSelecionada} closeSeparador={closeSeparador} excelDados={excelDados} />
      </Modal>
    </>
  );
};

const transformaJSON = (json, excelDados, configuracoes) => {
  const baixaCache = JSON.parse(json);
  const baixaRecuperada = new Baixas(baixaCache.codigoEmpresa, baixaCache.codigoContaContabil, configuracoes, excelDados.getData());
  baixaRecuperada.tabela = baixaCache.tabela;
  baixaRecuperada.recuperaCache();
  return baixaRecuperada;
};

export default Integracao;

const handleDownload = (textData) => {
  // Encode the text data using iconv-lite
  const encodedText = iconv.encode(textData, "ISO-8859-1");
  // Convert the encoded text to a Blob
  const blob = new Blob([encodedText], { type: "text/plain;charset=ISO-8859-1" });
  // Create a temporary URL for the Blob
  const url = URL.createObjectURL(blob);
  // Create a link element and trigger a click to download the file
  const link = document.createElement("a");
  link.href = url;
  link.download = "integracao.txt";
  document.body.appendChild(link);
  link.click();
  // Clean up by revoking the Object URL
  URL.revokeObjectURL(url);
  document.body.removeChild(link);
};
