export default class Opcao {
  constructor(
    parteHistoricoId,
    operacaoId,
    historicoId,
    ctPartida,
    codigo,
    codiEmp,
    codiConta,
    sbHistorico,
    baixaParcial = 0
  ) {
    this.parte_historico_id = parteHistoricoId;
    this.operacao_id = operacaoId;
    this.historico_id = historicoId;
    this.ct_partida = ctPartida;
    this.codigo = codigo;
    this.codi_emp = codiEmp;
    this.codi_conta = codiConta;
    this.sb_historico = sbHistorico;
    this.baixa_parcial = baixaParcial;
  }
}
