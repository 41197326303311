import React, { useState, useContext } from "react";
import { GlobalContext } from "../../../contextGlobal/context";
import { notify } from "../../../components/UI/Notificacao/Notificacao";

const MenuInferior = ({ componentes }) => {
  const { loading } = useContext(GlobalContext);
  const [tela, setTela] = useState(0);

  let clicked = (index) => setTela(index);

  if (loading) clicked = () => notify("Ainda há arquivos sendo carregados", "aviso");

  return <>{componentes[tela]}</>;
};

export default MenuInferior;
