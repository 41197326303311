const commonHeaders = {
  "Content-Type": "application/json",
  "Cache-Control": "no-cache",
  "x-api-key": "g<mT&=>?&H9kRr~(][xD*!&5/99<3A_2",
};

const baseUrl = "https://bessa.digital/api/integrador";

export const getFiltroEmpresa = async (notify) => {
  try {
    const dataReq = await fetch(`${baseUrl}/empresas`, {
      headers: commonHeaders,
    });

    const data = await dataReq.json();

    if (data) {
      return data.data;
    }
    return {
      status: 480,
      msg: "Servidor encontrou problemas",
    };
  } catch (error) {
    console.log(error);
    notify("Erro ao carregar empresa filtro!");
  }
};

export const getFiltroConta = async (notify, empresaSelecionada) => {
  try {
    const dataReq = await fetch(`${baseUrl}/${empresaSelecionada}/contas`, {
      headers: commonHeaders,
    });

    const data = await dataReq.json();

    if (data) {
      return data.data;
    }
    return {
      status: 480,
      msg: "Servidor encontrou problemas",
    };
  } catch (error) {
    console.log(error);
    notify("Erro ao carregar contas da empresa!");
  }
};

export const getFornecedores = async (notify, empresaSelecionada) => {
  try {
    const dataReq = await fetch(`${baseUrl}/${empresaSelecionada}/fornecedores`, {
      headers: commonHeaders,
    });

    const data = await dataReq.json();

    if (data) {
      return data.data;
    }
    return {
      status: 480,
      msg: "Servidor encontrou problemas",
    };
  } catch (error) {
    console.log(error);
    notify("Erro ao buscar fornecedores!");
  }
};

export const getClientes = async (notify, empresaSelecionada) => {
  try {
    const dataReq = await fetch(`${baseUrl}/${empresaSelecionada}/clientes`, {
      headers: commonHeaders,
    });

    const data = await dataReq.json();

    if (data) {
      return data.data;
    }
    return {
      status: 480,
      msg: "Servidor encontrou problemas",
    };
  } catch (error) {
    console.log(error);
    notify("Erro ao buscar clientes!");
  }
};

export const getServicos = async (notify, empresaSelecionada) => {
  try {
    const dataReq = await fetch(`${baseUrl}/${empresaSelecionada}/servicos`, {
      headers: commonHeaders,
    });

    const data = await dataReq.json();

    if (data) {
      return data.data;
    }
    return {
      status: 480,
      msg: "Servidor encontrou problemas",
    };
  } catch (error) {
    console.log(error);
    notify("Erro ao buscar serviços!");
  }
};

export const getBaixas = async (queryParametros, empresa) => {
  const params = queryParametros.split("/");
  const doc = params.find((p) => p.includes("num_doc"))?.split("=")?.[1] || 0;
  const value = params.find((p) => p.includes("vl_doc"))?.split("=")?.[1] || 0;
  const operation = params[0].split("?")[0];

  try {
    const dataReq = await fetch(`${baseUrl}/${empresa}/baixas/${doc}/${operation}s`, {
      headers: commonHeaders,
    });

    const data = await dataReq.json();

    return data.data.sort((a, b) => {
      const diffA = Math.abs(a.vlconsiderado - value);
      const diffB = Math.abs(b.vlconsiderado - value);
      return diffA - diffB;
    })
  } catch (error) {
    console.log(`erro ao buscar baixas ${error}`);
    return "erro";
  }
};

export const getNotasEmAberto = async (empresaSelecionada, qualRota, qualFornecedor) => {
  try {
    const dataReq = await fetch(`${baseUrl}/${empresaSelecionada}/notas/${qualFornecedor}/${qualRota}s`, {
      headers: commonHeaders,
    });

    const data = await dataReq.json();

    if (data) {
      return data.data;
    }
    return {
      status: 480,
      msg: "Servidor encontrou problemas",
    };
  } catch (error) {
    console.log(`Erro ao buscar notas : ${error}`);
  }
};

export const getContasDJD = async (empresaSelecionada, codigo, baixaEscolhida) => {
  try {
    const dataReq = await fetch(`${baseUrl}/${empresaSelecionada}/acumuladores/${codigo}/${baixaEscolhida}s`, {
      headers: commonHeaders,
    });

    const data = await dataReq.json();

    if (data) {
      return data.data[0];
    }
    return {
      status: 480,
      msg: "Servidor encontrou problemas",
    };
  } catch (error) {
    console.log(`erro ao buscar acumuladores${error}`);
  }
};

export const getOpcoes = async (empresaSelecionada, contaSelecionada) => {
  try {
    const dataReq = await fetch(`${baseUrl}/${empresaSelecionada}/opcoes/${contaSelecionada}`, {
      headers: commonHeaders,
    });

    const data = await dataReq.json();

    if (data) {
      return data.data;
    }
    return {
      status: 480,
      msg: "Servidor encontrou problemas",
    };
  } catch (error) {
    console.log(error);
  }
};

export const getOpcoesPeloHistorico = async (empresaSelecionada, contaSelecionada, pHistorico) => {
  const sanitizedpHistorico = pHistorico.replace(/[<>"{}^`|\\&?+/]/g, '');
  try {
    const dataReq = await fetch(`${baseUrl}/${empresaSelecionada}/opcoes/${contaSelecionada}${sanitizedpHistorico ? `/${sanitizedpHistorico}` : ""}`, {
      headers: commonHeaders,
    });

    const data = await dataReq.json();

    return data.data[0];
  } catch (error) {
    console.log(`Erro buscar opções pelo histórico :${error}`);
  }
};

export const salvaHistorico = async (novoHistorico) => {
  try {
    const dataReq = await fetch(`${baseUrl}/historicos`, {
      method: "POST",
      headers: commonHeaders,
      body: JSON.stringify({ historico: novoHistorico }),
    });

    const data = await dataReq.json();

    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const salvaParteHistorico = async (parteHistorico) => {
  const sanitizedParteHistorico = parteHistorico.replace(/[<>"{}^`|\\&?+/]/g, '');
  try {
    const dataReq = await fetch(`${baseUrl}/parte_historicos`, {
      method: "POST",
      headers: commonHeaders,
      body: JSON.stringify({ parte_historico: sanitizedParteHistorico }),
    });

    const data = await dataReq.json();

    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const salvaOperacao = async (novaOperacao) => {
  try {
    const dataReq = await fetch(`${baseUrl}/operacoes`, {
      method: "POST",
      headers: commonHeaders,
      body: JSON.stringify({ operacao: novaOperacao }),
    });
    const data = await dataReq.json();
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const salvaOpcao = async (opcao) => {
  try {
    const dataReq = await fetch(`${baseUrl}/opcoes`, {
      method: "POST",
      headers: commonHeaders,
      body: JSON.stringify({ opcao }),
    });
    const data = await dataReq.json();
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const getHistorico = async (id) => {
  try {
    const dataReq = await fetch(`${baseUrl}/historicos/${id}`, {
      headers: commonHeaders,
    });

    const data = await dataReq.json();

    return data.data;
  } catch (error) {
    console.log(`Erro ao buscar histórico pelo Id ${error}`);
  }
};

export const atualizaParteHistorico = async (parteHistorico, id) => {
  try {
    const dataReq = await fetch(`${baseUrl}/parte_historicos/${id}`, {
      method: "PUT",
      headers: commonHeaders,
      body: JSON.stringify({ parte_historico: parteHistorico }),
    });
    const data = await dataReq.json();

    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const atualizaOpcao = async (opcao, id) => {
  try {
    const dataReq = await fetch(`${baseUrl}/opcoes/${id}`, {
      method: "PUT",
      headers: commonHeaders,
      body: JSON.stringify({ opcao }),
    });

    const data = await dataReq.json();

    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const deletaOpcao = async (id) => {
  try {
    const dataReq = await fetch(`${baseUrl}/opcoes/${id}`, {
      method: "DELETE",
      headers: commonHeaders,
    });
    const data = await dataReq.json();
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

export const buscaHistoricos = async () => {
  try {
    const dataReq = await fetch(`${baseUrl}/historicos`, {
      headers: commonHeaders,
    });

    const data = await dataReq.json();

    return data.data;
  } catch (error) {
    console.log(`Erro au buscar todos históricos ${error}`);
  }
};

export const baixaArquivo = async (arquivoString, setLink) => {
  try {
    const dataReq = await fetch(`${baseUrl}/baixa_arquivo`, {
      method: "POST",
      headers: commonHeaders,
      body: JSON.stringify({ arquivoString }),
    });

    setLink("api/integrador/baixa_arquivo");
  } catch (error) {
    console.log(error);
  }
};
