import React, { useContext } from "react";
import Button from "../../../../../../../components/UI/Button/Button";
import Icone from "../../../../../../../components/UI/Icone/Icone";
import { IntegradorContext } from "../../../../../util/context/context";
import { geraTabela } from "../../../util/controller";
import {
  geraAtributos,
  vencimentoFormatado,
  format,
} from "../../util/analiseController";
import { AnaliseContext } from "../../util/context";
const BaixaEscolhida = () => {
  const { baixaSelecionada, qualPromessa, closeModal } =
    useContext(AnaliseContext);
  const {
    despachaBaixaSelecionada,
    despachante,
    baixas,
    setSeparador,
    setLinhaSelecionada,
  } = useContext(IntegradorContext);
  const handleClick = (valor) => {
    const padraoBaixaParcial = valor;

    baixas.tabela[
      qualPromessa
    ].baixaSelecionada.configuracoes.padraoBaixaParcial = padraoBaixaParcial;

    despachaBaixaSelecionada(
      baixas.tabela[qualPromessa].baixaSelecionada,
      qualPromessa,
      padraoBaixaParcial
    );

    despachante({
      tabelaDados: geraTabela(baixas, setSeparador, setLinhaSelecionada),
    });
    localStorage.setItem("Integrador", JSON.stringify(baixas));

    closeModal();
  };

  if (baixaSelecionada && baixaSelecionada.operacao !== 1) {
    const valores = [
      baixaSelecionada.codigo,
      baixaSelecionada.nome,
      baixaSelecionada.cnpj,
      baixaSelecionada.crf,
      baixaSelecionada.documento,
      `${format(baixaSelecionada.irrf)}`,
      `${format(baixaSelecionada.iss)}`,
      baixaSelecionada.parcela,
      `${format(baixaSelecionada.vlpg)}`,
      `${format(baixaSelecionada.vltotal)}`,
      `${format(baixaSelecionada.vlconsiderado)}`,
      vencimentoFormatado(baixaSelecionada.vencimento),
    ];

    let label = geraLabel(baixaSelecionada);

    return (
      <div className="analiseNotaEscolhida">
        <div className="analiseNotaEscolhida__headBox">Nota Selecionada </div>
        <span className="analiseNotaEscolhida__circulo">
          <Icone icone="faCheck" size="1x" />
        </span>
        <div className="analiseNotaEscolhida__info">
          {geraAtributos(valores, atributosNota)}
        </div>
        {label && (
          <Button clicked={() => handleClick(label.value)}>{label.text}</Button>
        )}
      </div>
    );
  }

  if (baixaSelecionada && baixaSelecionada.operacao === 1) {
    const valores = [
      baixaSelecionada.contraPartida.label,
      baixaSelecionada.contraPartida.value,
      baixaSelecionada.codigoDoHistorico,
      baixaSelecionada.historicoValue,
    ];

    return (
      <div className="analiseNotaEscolhida">
        <div className="analiseNotaEscolhida__headBox">Nota Selecionada </div>
        <span className="analiseNotaEscolhida__circulo">
          <Icone icone="faCheck" size="1x" />
        </span>
        <div className="analiseNotaEscolhida__info">
          {geraAtributos(valores, atributosContaContabil)}
        </div>
      </div>
    );
  }

  return <div className="nenhuma_baixa">Nenhuma Baixa Selecionada</div>;
};

const geraLabel = (baixaSelecionada) => {
  const { rota, valorDoPagamento, valorDaNota } = baixaSelecionada;

  if (rota === "comDesconto" || rota === "comJuros") {
    return { text: "Na verdade é com Baixa Parcial", value: true };
  }

  if (rota === "comBaixaParcial") {
    if (valorDoPagamento > valorDaNota) {
      return { text: "Na verdade é com Juros", value: false };
    }

    if (valorDoPagamento < valorDaNota) {
      return { text: "Na verdade é com Desconto", value: false };
    }
  }

  return null;
};

const atributosContaContabil = [
  `Contra Partida: `,
  `Codigo Da Contra Partida: `,
  `Codigo Do Histórico: `,
  `Histórico: `,
];

const atributosNota = [
  `Código: `,
  `Nome: `,
  `CNPJ: `,
  `CRF: `,
  `Documento: `,
  `IRRF: `,
  `ISS: `,
  `Parcela: `,
  `Valor Pago: `,
  `Valor Total: `,
  `Valor em aberto: `,
  `Vencimento: `,
];

export default BaixaEscolhida;
