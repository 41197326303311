import React from "react";
import PropTypes from "prop-types";

/**
 * @param props {
 * @param children: Nome do Botão
 * @param clicked: Função on-click do botao
 * @param btnType: entre['', 'Success' ou 'Danger'], altera o estilo do botão
 * }
 */

const Button = ({
  children,
  clicked = () => alert("Função Default"),
  btnType = "acao",
  classe,
  download = false,
}) => {
  const getButtao = () => {
    return (
      <div className={classe}>
        <button
          className={`botaoReact botaoReact__${btnType}`}
          onClick={clicked}
          type="button"
          download={download}
        >
          {children}
        </button>
      </div>
    );
  };

  return getButtao();
};

Button.propTypes = {
  clicked: PropTypes.func,
  btnType: PropTypes.oneOf([
    "filtrar",
    "deletar",
    "salvar",
    "cancelar",
    "acao",
  ]),
};

export default Button;
