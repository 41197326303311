import React from "react";
import { getContasDJD, baixaArquivo } from "../../../../../helpers/requisicoesHttp/integradorRequisicoes";
import Icone from "../../../../../components/UI/Icone/Icone";
import cloneDeep from "lodash/cloneDeep";

export function geraTabela(baixas, setSeparador = () => { }, setLinhaSelecionada = () => { }) {
  const temBaixasSaidaServicoAbertas = baixas.todasBaixasEmAbertoSaidaServico.length;
  const temBaixasEntradaAbertas = baixas.todasBaixasEmAbertoEntrada.length;
  const { tabela } = baixas;

  let arr = [];
  const { format } = formatter;

  //BODY

  for (let index = 0; index < tabela.length; index++) {
    const linha = tabela[index];

    const { data, historico, documento, entrada, saida } = linha;
    let conta = baixas.codi_cta || "";
    let valor = entrada || entrada === 0 ? format(entrada) : format(saida);
    let color = entrada || entrada === 0 ? "blue" : "red";
    let status = geraStatus(linha, index, temBaixasEntradaAbertas, temBaixasSaidaServicoAbertas);

    arr.push({
      status: status,
      conta: conta,
      data: data,
      documento: documento,
      historico: historico,
      valor: (
        <span className={color} style={{ color: color }}>
          {valor}
        </span>
      ),
      separador: iconeSeparador(index, setLinhaSelecionada, setSeparador),
    });
  }

  return arr;
}

const geraStatus = (linha, index, temBaixasEntradas, temBaixasSaidas) => {
  const { entrada, saida, baixasEncontradas, baixaSelecionada } = linha;

  if (baixaSelecionada) {
    return (
      <>
        <Icone key={index} data-posicao={index} icone={baixaSelecionada.tickIcon} size="1x" className={baixaSelecionada.tickColor} style={{ color: baixaSelecionada.tickColor }} />
      </>
    );
  }

  if (!baixasEncontradas) {
    return <Icone key={index} data-key={index} icone="faSpinner" className="fa-spin" size="1x" />;
  }

  if (baixasEncontradas.length >= 1 || (entrada && temBaixasSaidas) || (saida && temBaixasEntradas)) {
    return (
      <>
        <Icone key={index} data-posicao={index} icone="falifering" className="purple" style={{ color: "purple" }} size="1x" />
      </>
    );
  }

  return (
    <>
      <Icone key={index} data-tip="ZAWARUDO" data-posicao={index} icone="faTimes" className="red" style={{ color: "red" }} size="1x" />
    </>
  );
};

const iconeSeparador = (index, setLinhaSelecionada, setSeparador) => (
  <Icone
    icone="faCopy"
    size="1x"
    onClick={(e) => {
      e.stopPropagation();
      setLinhaSelecionada(index);
      setSeparador(true);
    }}
    title="Separador"
  />
);

export function preparaDocumento(baixas) {
  const baixasPreparada = cloneDeep(baixas);

  baixasPreparada.tabela = baixasPreparada.tabela.filter((dadosPlanilha) => {
    return dadosPlanilha.baixaSelecionada;
  });

  return baixasPreparada;
}

export async function enviaDocumento(documentoString, setLink) {
  try {
    await baixaArquivo(documentoString, setLink);
  } catch (error) {
    console.log(error);
  }
}

export async function geraDocumento(baixas, setPercentage) {
  const { codigoEmpresa, codigoContaContabil, tabela } = baixas;
  const numeroDeLinhas = tabela.length;

  let documento = "";
  setPercentage(0);

  let acumuladoresErrors = []

  for (let index = 0; index < numeroDeLinhas; index++) {
    const dadosPlanilha = tabela[index];
    const { entrada, saida, data, baixaSelecionada } = dadosPlanilha;

    let baixaEscolhida;

    switch (baixaSelecionada.operacao) {
      case 1:
        baixaEscolhida = "contabil";
        break;
      case 2:
        baixaEscolhida = "entrada";
        break;
      case 3:
        baixaEscolhida = "saida";
        break;
      case 4:
        baixaEscolhida = "servico";
        break;
      default:
        baixaEscolhida = "ERRO";
        break;
    }

    if (baixaEscolhida === "ERRO") continue;

    //Dados obtidos pelo Dominio
    let codigoNF;
    let parcela;
    let contraPartida;
    let fornecedor;
    let codigoDoHistorico;
    let contasDJD;
    if (baixaEscolhida !== "contabil") {
      codigoNF = baixaSelecionada.codigo.toString();
      parcela = baixaSelecionada.parcela.toString();

      contasDJD = await getContasDJD(codigoEmpresa, codigoNF, baixaEscolhida);
      if (!contasDJD) {
        acumuladoresErrors.push({ linha: index + 1, nota: codigoNF, tipo: baixaEscolhida })
        continue;
      }
      fornecedor = baixaSelecionada.nome;
    }
    let historico;
    if (baixaEscolhida === "contabil") {
      codigoDoHistorico = baixaSelecionada.codigoDoHistorico.toString();
      historico = baixaSelecionada.historicoValue;
      contraPartida = baixaSelecionada.contraPartida.value.toString();
    }

    //Dados obtidos pelo Integrador
    let juros = dadosPlanilha.juros ? transformaEmDuasCasasDecimais(dadosPlanilha.juros) : transformaEmDuasCasasDecimais(baixaSelecionada.juros);
    let desconto = dadosPlanilha.desconto ? transformaEmDuasCasasDecimais(dadosPlanilha.desconto) : transformaEmDuasCasasDecimais(baixaSelecionada.desconto);
    let valorPago = entrada || saida;
    let valorPrincipal = transformaEmDuasCasasDecimais(Number(valorPago) - (Number(juros)) + Number(desconto));
    valorPago = juros ? transformaEmDuasCasasDecimais(valorPago - juros).toString() : valorPago.toString();

    let dataDoPagamento = data;

    ///////////////////////////////
    if (baixaEscolhida === "contabil") {
      /**Linha 1 */
      documento += `6000|X||||\n`;

      /**Linha 2 */
      if (entrada) documento += `6100|${dataDoPagamento}|${codigoContaContabil}|${contraPartida}|${entrada.toString().replace(".", ",")}|${codigoDoHistorico}|${historico}|||||\n`;
      if (saida) documento += `6100|${dataDoPagamento}|${contraPartida}|${codigoContaContabil}|${saida.toString().replace(".", ",")}|${codigoDoHistorico}|${historico}|||||\n`;
    }

    ///////////////////////////////
    if (baixaEscolhida === "entrada") {
      /**BNFE1 */
      documento += `BNFE1|${codigoEmpresa}|${codigoNF}|${parcela}|${valorPago.toString().replace(".", ",")}|1|${dataDoPagamento}|  \n`;

      if (juros > 0) documento += `BNFE1|${codigoEmpresa}|${codigoNF}|${parcela}|${juros.toString().replace(".", ",")}|2|${dataDoPagamento}|   \n`;

      if (desconto > 0) documento += `BNFE1|${codigoEmpresa}|${codigoNF}|${parcela}|${desconto.toString().replace(".", ",")}|4|${dataDoPagamento}|  \n`;

      /**BNFE2 */

      let historicoDoLancamento = `Pagamento da nota fiscal nº ${dadosPlanilha.documento || baixaSelecionada.documento} do Fornecedor ${fornecedor} nesta data.`;

      documento += `BNFE2|${dataDoPagamento}|${contasDJD.conta_debito}|${codigoContaContabil}|${valorPago.toString().replace(".", ",")}||${historicoDoLancamento}|  \n`;

      if (juros > 0) {
        documento += `BNFE2|${dataDoPagamento}|${contasDJD.conta_juros}|${codigoContaContabil}|${juros.toString().replace(".", ",")}||${historicoDoLancamento}|   \n`;
      }

      if (desconto > 0) {
        documento += `BNFE2|${dataDoPagamento}|${contasDJD.conta_debito}|${contasDJD.conta_desconto}|${desconto.toString().replace(".", ",")}||${historicoDoLancamento}|  \n`;
      }
    }

    //////////////////////////////
    if (baixaEscolhida === "saida") {
      /**BNFS1 */

      documento += `BNFS1|${codigoEmpresa}|${codigoNF}|${parcela}|${valorPago.toString().replace(".", ",")}|1|${dataDoPagamento}|  \n`;

      if (juros > 0) {
        documento += `BNFS1|${codigoEmpresa}|${codigoNF}|${parcela}|${juros.toString().replace(".", ",")}|2|${dataDoPagamento}|   \n`;
      }

      if (desconto > 0) {
        documento += `BNFS1|${codigoEmpresa}|${codigoNF}|${parcela}|${desconto.toString().replace(".", ",")}|4|${dataDoPagamento}|  \n`;
      }

      /**BNFS2 */

      //Dados obtidos pelo Integrador
      let historicoDoLancamento = `Recebimento da nota fiscal nº  ${dadosPlanilha.documento || baixaSelecionada.documento} do Cliente ${fornecedor} nesta data.`;

      documento += `BNFS2|${dataDoPagamento}|${codigoContaContabil}|${contasDJD.conta_debito}|${valorPago.toString().replace(".", ",")}||${historicoDoLancamento}|  \n`;

      if (juros > 0) {
        documento += `BNFS2|${dataDoPagamento}|${codigoContaContabil}|${contasDJD.conta_juros}|${juros.toString().replace(".", ",")}||${historicoDoLancamento}|   \n`;
      }

      if (desconto > 0) {
        documento += `BNFS2|${dataDoPagamento}|${contasDJD.conta_desconto}|${contasDJD.conta_debito}|${desconto.toString().replace(".", ",")}||${historicoDoLancamento}|  \n`;
      }
    }

    //////////////////////////////
    if (baixaEscolhida === "servico") {
      /**BNFSV1 */

      documento += `BNFSV1|${codigoEmpresa}|${codigoNF}|${parcela}|${valorPago.toString().replace(".", ",")}|1|${dataDoPagamento}|  \n`;

      if (juros > 0) {
        documento += `BNFSV1|${codigoEmpresa}|${codigoNF}|${parcela}|${juros.toString().replace(".", ",")}|2|${dataDoPagamento}|   \n`;
      }

      if (desconto > 0) {
        documento += `BNFSV1|${codigoEmpresa}|${codigoNF}|${parcela}|${desconto.toString().replace(".", ",")}|4|${dataDoPagamento}|  \n`;
      }

      /**BNFSV2 */

      //Dados obtidos pelo Integrador
      let historicoDoLancamento = `Recebimento da nota fiscal nº  ${dadosPlanilha.documento || baixaSelecionada.documento} do Cliente ${fornecedor} nesta data.`;

      documento += `BNFSV2|${dataDoPagamento}|${codigoContaContabil}|${contasDJD.conta_debito}|${valorPago.toString().replace(".", ",")}||${historicoDoLancamento}|  \n`;

      if (juros > 0) {
        documento += `BNFSV2|${dataDoPagamento}|${codigoContaContabil}|${contasDJD.conta_juros}|${juros.toString().replace(".", ",")}||${historicoDoLancamento}|   \n`;
      }

      if (desconto > 0) {
        documento += `BNFSV2|${dataDoPagamento}|${contasDJD.conta_desconto}|${contasDJD.conta_debito}|${desconto.toString().replace(".", ",")}||${historicoDoLancamento}|  \n`;
      }
    }
    setPercentage(Math.ceil(((index + 1) / parseInt(numeroDeLinhas)) * 100));
  }

  if (acumuladoresErrors.length) {
    const linhasComProblemas = acumuladoresErrors.map((l) => `linha: ${l.linha} , nota: ${l.nota} , tipo ${l.tipo}`).join(",\n");
    alert(`⚠️Atenção: as linhas a seguir foram ignoradas por erro ao buscar acumuladores \n${linhasComProblemas}`);
  }

  return documento;
}

//Formatador
const formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

const transformaEmDuasCasasDecimais = (num) => {
  let valorDuasCasas = Math.round(parseFloat(num) * 100) / 100;

  if (isNaN(valorDuasCasas.toString())) {
    return "";
  }

  return valorDuasCasas.toString();
};
