import React, { useState } from "react";
import ReactLoading from "react-loading";
import styled from "tachyons-components";

const useLoading = (estadoInicialdoLoading) => {
  const [loading, setLoading] = useState(estadoInicialdoLoading);

  const componenteReact = loading === true && (
    <Section>
      <Article key={list[0].prop}>
        <ReactLoading type={list[0].prop} color="#1abc9c" />
      </Article>
    </Section>
  );

  return [componenteReact, setLoading];
};

const Section = styled("div")`
flex flex-wrap content-center justify-center w-100 h-10 bg-transparent`;

const Article = styled("div")`
w-25 ma2 h4 items-center justify-center flex flex-column flex-wrap`;

const Prop = styled("h3")`
f5 f4-ns mb0 black`;

const Title = styled("h1")`
f4 f3-ns black w-100 tc`;

const list = [
  {
    prop: "bars",
    name: "Bars",
  },
];

export default useLoading;
