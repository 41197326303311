import React, { useContext } from "react";
import { IntegradorContext } from "../../util/context/context";

import Toggle from "react-toggle";
import "react-toggle/style.css";
import Configuracoes from "../Integracao/util/constructors/Configuracoes/Configuracoes";
import Button from "../../../../components/UI/Button/Button";

const ConfiguracoesIntegrador = () => {
  //Context
  const { despachante, configuracoes } = useContext(IntegradorContext);

  const { padraoVencimento, padraoBaixaParcial } = configuracoes;

  const togglePadraoVencimento = () => {
    const configuracoes = new Configuracoes(!padraoVencimento, padraoBaixaParcial);
    despachante({ configuracoes });
  };

  const togglePadraoBaixaParcial = () => {
    const configuracoes = new Configuracoes(padraoVencimento, !padraoBaixaParcial);
    despachante({ configuracoes });
  };

  return (
    <>
      <div className="integradorToggleContainer">
        <div className="integradorToggleContainer__box">
          <div>
            <h1>Usar Padrão Vencimento</h1>
            <p>Essa opção permite que o Integrador faça baixa da Nota com a data de vencimento e valor mais próximos</p>
          </div>
          <div>
            <Toggle onChange={togglePadraoVencimento} id="togglePadraoVencimento" defaultChecked={padraoVencimento} />
          </div>
        </div>
        <div className="integradorToggleContainer__box">
          <div>
            <h1>Usar Padrão Baixa Parcial</h1>
            <p>Essa opção permite mudar o padrão da baixa para Baixa Parcial ao invés de Baixa com Desconto. </p>
          </div>
          <div>
            <Toggle onChange={togglePadraoBaixaParcial} id="togglePadraoBaixaParcial" defaultChecked={padraoBaixaParcial} />
          </div>
        </div>
        <div className="integradorToggleContainer__box" >
          <Button clicked={limparDadosNavegacao}>Limpar cache🧹</Button>
        </div>
      </div>
    </>
  );
};

export default ConfiguracoesIntegrador;


function limparDadosNavegacao() {
  const temCerteza = confirm("Isso limpará os dados da planilha e reiniciará a página");
  if (temCerteza) {
    localStorage.removeItem("Integrador");
    alert("Dados do cache limpos. Reiniciando...");
    window.location.reload();
  }
}
