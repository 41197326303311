import { notify } from "../../../components/UI/Notificacao/Notificacao";
import {
  getFiltroEmpresa,
  getFiltroConta,
  getFornecedores,
  getClientes,
  getServicos,
} from "../../../helpers/requisicoesHttp/integradorRequisicoes";

export async function geraSelectEmpresa(empresaFiltroHandler) {
  const listaEmpresas = await getFiltroEmpresa(notify);
  empresaFiltroHandler({ listaEmpresas });
}

export async function geraSelectConta(contaFiltroHandler, empresaSelecionada) {
  const listaContas = await getFiltroConta(notify, empresaSelecionada);
  contaFiltroHandler({ listaContas });
}

export async function geraSelectFornecedores(despachante, empresaId) {
  const listaFornecedores = await getFornecedores(notify, empresaId);

  despachante({ listaFornecedores: listaFornecedores });
}

export async function geraSelectClientes(despachante, empresaId) {
  const listaClientes = await getClientes(notify, empresaId);

  despachante({ listaClientes: listaClientes });
}

export async function geraSelectServicos(despachante, empresaId) {
  const listaServicos = await getServicos(notify, empresaId);

  despachante({ listaServicos: listaServicos });
}
