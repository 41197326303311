import { element, func } from "prop-types";
import React, { useState } from "react";
import Icone from "../Icone/Icone";

/**
 * @param props {
 * @param children: elemento do react
 * @param filter: função toggle de filtro
 */

const Card = ({ children, filter, mais }) => {
  const [minimize, setMinimize] = useState(false);
  const clickMin = () => {
    setMinimize(!minimize);
  };
  return (
    <div className="card card-teste">
      <div className="card__icone_container">
        <Icone
          onClick={(e) => clickMin()}
          className="card__icone_container--icone"
          icone="faWindowMinimize"
          size="1x"
        />
        {filter ? (
          <>
            <Icone
              onClick={(e) => filter()}
              className="card__icone_container--icone card__icone_container--filtro"
              icone="faFilter"
              size="1x"
            />
            <span className="card__icone_container--mais">+</span>
          </>
        ) : null}
        {mais ? (
          <button
            style={{
              padding: "0px 10px 0px 10px",
              opacity: "0.5",
              fontWeight: "bold",
              marginRight: "10px",
              borderRadius: "5px",
            }}
            onClick={(e) => mais.func()}
          >
            {mais.txt}
          </button>
        ) : null}
      </div>

      {/** Content Container */}
      <div
        className={
          !minimize ? "card__container" : "card__container--minimizado"
        }
      >
        {children}
      </div>
    </div>
  );
};

export default Card;
