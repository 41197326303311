import React from "react";
import PropTypes from "prop-types";
import Icone from "../Icone/Icone";

/**
 * @param props {
 * @param closeModal: Função para fechar o modal
 * @param children: Conteudo do modal
 * @param title: Titulo do Modal
 * }
 */

//Olhar modal depois

const Modal = ({
  closeModal,
  children,
  title = null,
  isModalOpen,
  width = "",
  min_width = "",
  max_width = ""
}) => {
  React.useEffect(() => {
    if (!isModalOpen) {
      document.getElementsByTagName("body")[0].style.overflowY = "auto";
    } else {
      document.getElementsByTagName("body")[0].style.overflowY = "hidden";
    }
  }, [isModalOpen]);

  const handleOutsideClick = (e) => {
    if (e.target.id === "Modal") closeModal();
  };

  const getModal = () => {
    if (isModalOpen)
      return (
        <div id="Modal" className="modal_BG" onClick={handleOutsideClick}>
          <div
            className="modal_container modal_container_global"
            style={{ width: width, "minWidth": min_width, "maxWidth": max_width }}
          >
            <div className="titleContainer">
              {title && <h1>{title}</h1>}
              <button className="titleContainer__close" onClick={closeModal}>
                <Icone icone="faTimesCircle" size="1x" />
              </button>
            </div>

            <div className="modal_container__content">{children}</div>
          </div>
        </div>
      );
    return "";
  };

  return getModal();
};

Modal.propTypes = {
  closeModal: PropTypes.func.isRequired,

  title: PropTypes.string,
};

export default Modal;
