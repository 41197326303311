export const darkTheme = {
  bodyBackground: "rgb(18, 18, 18)",
  header: "#1e2125",
  textPrimary: "#FFF",
  textPrimaryImportant: "#FFF !important",
  textHeader: "#FAFAFA",
  dropDownMenuBackground: "#000",
  dropDownMenuText: "#FFF",
  cardBackground: "#2E353B !important",
  cardText: "rgb(250, 250, 250)",
  dropZoneText: "#FFF",
  dropZoneTextHover: "#b3b2bd",
  dropZoneBackgroundImportant: "#2E353B !important",
  selectBackground: "#2E353B !important",
  selectPlaceholderImportant: "hsl(0,0%,50%) !important",
  selectDisabledBackground: "#acacac !important",
  dateTimeSeparador: "#000",
  inputText: "#000",
  inputLabel: "#FFF",
  inputOutlineColorImportant: "#d8dbe0 !important",
  tableColor: "#000",
  tabelaReactCorImportant: "#FFF !important",
  tabelaReactBackgroundEven: "#1E242D;",
  tabelaReactBackgroundTH: "#eeeeee",
  tabelaReactBorderColor: "#1E242D",
  conferirArquivosTextColor: "#e4e4e4",
  backgroundTextareaImportant: "transparent !important",
  integradorTabsBackground: "#2E353B",
  integradorTabsDisabledBackground: "#6c6c6c",
  integradorTabsDisabledColor: "#979797",
  integradorToggleShadow: "rgba(255, 255, 255, 0.1) 0px 2px 8px 0px",
  integradorSpanColorBlue: "#9f9fff !important",
  integradorSpanColorRed: "#ff4d4d !important",
  integradorSpanColorPurple: "#a64ca6 !important",
  integradorSpanColorGreen: "#66b266 !important",
  integradorTableHoverBackground: "#1c212d",
  analiseTabsBackground: "#2E353B",
  analiseTabsColor: "#FFF",
  separadorTableColor: "#bababa",
  integradorOpcoesAlertTextShadow: "none",
};
