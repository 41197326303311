import React, { useContext, useEffect, useRef, useState } from "react";
import { IntegradorContext } from "../../util/context/context";
import Button from "../../../../components/UI/Button/Button";
import jspreadsheet from "jspreadsheet-ce";
import { geraDocumento } from "./util/controller";
import iconv from "iconv-lite";

import "../../../../../node_modules/jspreadsheet-ce/dist/jspreadsheet.css";

const Espelho = () => {
  //Context
  const { excelDados, despachante, excelEspelho, contaContabilSelecionada } = useContext(IntegradorContext);
  const [link] = useState("");

  const el = useRef(null);

  useEffect(() => {
    let data = [[]];

    if (excelDados) data = converteParaEspelho(excelDados.getData());

    const configuracaoGrid = {
      data: data,
      columns: [
        { title: "Data", width: 120 },
        { title: "Historico", width: 120 },
        { title: "Documento", width: 120 },
        { title: "Debito", width: 120 },
        { title: "Credito", width: 130 },
      ],
      minDimensions: [1, 1],
    };
    despachante({ excelEspelho: jspreadsheet(el.current, configuracaoGrid) });
  }, [excelDados]);

  return (
    <>
      <Button
        clicked={async () => {
          try {
            let documento = geraDocumento(excelEspelho.getData(), contaContabilSelecionada);
            handleDownload(documento);
          } catch (error) {
            console.log(error);
          }
        }}
        classe="botao_gerador_integrador"
      >
        Gerar
      </Button>
      <div className="espelhoContainer">
        <button
          onClick={() => {
            excelEspelho.setData([[]]);
            despachante({ excelEspelho: "" });
          }}
        >
          RESETAR
        </button>
        <div>
          <div ref={el}></div>
        </div>
        <div style={{ display: "none" }}>
          <iframe src={link} />
        </div>
      </div>
    </>
  );
};

const converteParaEspelho = (objeto) => {
  const planilha = objeto;

  return planilha.map((linha) => [linha[0], linha[1], linha[2], linha[3], linha[4]]);
};

export default Espelho;

const handleDownload = (textData) => {
  const encodedText = iconv.encode(textData, "ISO-8859-1");
  const blob = new Blob([encodedText], { type: "text/plain;charset=ISO-8859-1" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "espelho.txt";
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
  document.body.removeChild(link);
};
