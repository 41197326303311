import React, { useContext, useState, useEffect } from "react";

import BaixaEscolhida from "./children/BaixaEscolhida/BaixaEscolhida";
import UmaBaixa from "./children/UmaBaixa/UmaBaixa";
import MaisBaixas from "./children/MaisBaixas/MaisBaixas";
import { IntegradorContext } from "../../../util/context/context";
import { AnaliseContext } from "./util/context";

const Analise = ({ closeModal, qualPromessa }) => {
  //Context
  const { baixas } = useContext(IntegradorContext);
  const { despachanteAnalise } = useContext(AnaliseContext);
  const { data, historico, documento, desconto, entrada, saida, juros, baixaSelecionada } = baixas.tabela[
    qualPromessa
  ];
  //preventing floating point error
  const unsafeDesconto = desconto || baixaSelecionada?.desconto;
  const unsafeJuros = juros || baixaSelecionada?.juros;
  const safeDesconto = isNaN(unsafeDesconto) ? unsafeDesconto : Number.parseFloat(Number(unsafeDesconto).toFixed(2)) || "";
  const safeJuros = isNaN(unsafeJuros) ? unsafeJuros : Number.parseFloat(Number(unsafeJuros).toFixed(2)) || "";

  //State

  const [showBaixaEscolhida, setShowBaixaEscolhida] = useState(true);
  const [showUmaBaixa, setUmaBaixa] = useState(false);
  const [showMaisBaixas, setShowMaisBaixas] = useState(false);

  useEffect(() => {
    const despacho = baixas.tabela[qualPromessa];
    despacho.closeModal = closeModal;
    despacho.qualPromessa = qualPromessa;
    despachanteAnalise(despacho);
  }, []);

  return (
    <div className="integradorAnaliseContainer">
      <div className="integradorInfoFrenteModal">
        <span>
          <b>Data:</b> {data}
        </span>
        <span>
          <b>Documento: </b>
          {documento}
        </span>
        <span>
          <b>Histórico: </b>
          {historico}
        </span>
        <span>
          <b>Valor:</b> {entrada ? entrada : saida}
        </span>
        <span>
          <b>Desconto:</b> {safeDesconto}
        </span>
        <span>
          <b>Juros: </b>
          {safeJuros}
        </span>
      </div>
      <div className="integradorBaixasContainer_table">
        <div className="integradorBaixasContainer_tabs">
          <button
            className={`tablinks ${showBaixaEscolhida ? "active" : null
              } integradorBaixa_button_tabs`}
            onClick={() => {
              setShowBaixaEscolhida(true);
              setUmaBaixa(false);
              setShowMaisBaixas(false);
            }}
          >
            Baixa Escolhida
          </button>
          <button
            className={`tablinks ${showUmaBaixa ? "active" : null
              } integradorBaixa_button_tabs`}
            onClick={() => {
              setShowBaixaEscolhida(false);
              setUmaBaixa(true);
              setShowMaisBaixas(false);
            }}
          >
            Uma Baixa
          </button>
          <button
            className={`tablinks ${showMaisBaixas ? "active" : null
              } integradorBaixa_button_tabs`}
            onClick={() => {
              setShowBaixaEscolhida(false);
              setUmaBaixa(false);
              setShowMaisBaixas(true);
            }}
          >
            Mais de uma Baixa
          </button>
        </div>

        {showBaixaEscolhida && <BaixaEscolhida />}

        {showUmaBaixa && <UmaBaixa />}

        {showMaisBaixas && <MaisBaixas />}
      </div>
    </div>
  );
};

export default Analise;
